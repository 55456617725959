import { FC, useEffect } from "react";
import { Wrapper, Body, Content } from "./primary-layout.style";
import { PrimaryLayoutProps } from "./primary-layout.types";
import Footer from "../../organisms/footer/footer";
import { useTranslation } from "react-i18next";
import VerticalNavigation from "../../organisms/vertical-navigation";
import { useUser } from "../../../contexts/user-context";
import { Outlet } from "react-router-dom";

const PrimaryLayout: FC<PrimaryLayoutProps> = () => {
  const { i18n } = useTranslation();
  const user = useUser();

  useEffect(() => {
    i18n.changeLanguage(user ? user.language : "en");
  }, [i18n, user]);

  return (
    <Wrapper>
      <VerticalNavigation />
      <Body>
        <Content>
          <Outlet />
        </Content>
        <Footer />
      </Body>
    </Wrapper>
  );
};

export default PrimaryLayout;
