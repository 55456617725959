import styled from "styled-components";

interface WrapperProps {
  status: string;
}

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.basic.filled[100]};
  border-radius: ${({ theme }) => theme.borderRadius};
  border: 1px solid ${({ theme }) => theme.colors.basic.filled[300]};

  border-top: ${({ theme, status }) => {
    if (status === "completed") {
      return `4px solid ${theme.colors.primary.filled[500]}`;
    }
    if (status === "error") {
      return `4px solid ${theme.colors.danger.filled[500]}`;
    }
    if (status === "in progress") {
      return `4px solid ${theme.colors.warning.filled[500]}`;
    }
  }};
`;

export const Header = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  padding: 20px 24px;
  min-width: 0; /* Set min-width to allow text truncation */
  width: 100%; /* Set width to stretch the Header within the grid cell */
  max-width: 100%; /* Set a maximum width for the Header to prevent stretching */

  & > * {
    /* This ensures the child elements inside the Header can wrap, but the Header won't expand */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const Footer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.basic.filled[300]};
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
`;
