import styled from "styled-components";

interface StepperProps {
  status: string;
}

export const StepperItemCircle = styled.div<StepperProps>`
  border-radius: 100%;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;

  color: ${({ status, theme }) => {
    switch (status) {
      case "active":
        return theme.colors.primary.filled[500];
      case "completed":
        return theme.colors.primary.filled[500];
      default:
        return theme.colors.basic.filled[500];
    }
  }};

  border: 1px solid
    ${({ status, theme }) => {
      switch (status) {
        case "active":
          return theme.colors.primary.filled[500];
        case "completed":
          return theme.colors.primary.filled[500];
        default:
          return theme.colors.basic.filled[500];
      }
    }};

  background-color: ${({ status, theme }) => {
    switch (status) {
      case "active":
        return theme.colors.primary.transparent[0.08];
      case "completed":
        return theme.colors.primary.filled[500];
      default:
        return theme.colors.basic.transparent[0.08];
    }
  }};
`;

export const StepperItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

export const StepperText = styled.p<StepperProps>`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  color: ${({ status, theme }) => {
    switch (status) {
      case "active":
        return theme.colors.primary.filled[500];
      case "completed":
        return theme.colors.primary.filled[500];
      default:
        return theme.colors.basic.filled[500];
    }
  }};
`;
