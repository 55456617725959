import { FC } from "react";
import { Card, CardHeader, ButtonContainer } from "./settings-card.style";
import { SettingsCardProps } from "./settings-card.types";
import Button from "../button";
import H6 from "../../atoms/h6";
import { useTranslation } from "react-i18next";

const SettingsCard: FC<SettingsCardProps> = ({
  Icon,
  children,
  onClick,
  disabled,
  buttonLabel,
  headerLabel,
}) => {
  const { t } = useTranslation();

  return (
    <Card>
      <CardHeader>
        <Icon />
        <H6>{headerLabel}</H6>
      </CardHeader>
      {children}
      {onClick && (
        <ButtonContainer>
          <Button
            size="small"
            onClick={onClick}
            disabled={disabled}
            text={t(buttonLabel!!)}
          />
        </ButtonContainer>
      )}
    </Card>
  );
};

export default SettingsCard;
