import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useApi } from "./api-context";
import User from "../types/user";
import { useAuth } from "./auth-context";
import PageState from "../components/organisms/page-state";
import { ButtonContainer, Card, FullScreen } from "../styles/commons.styles";
import Transition from "../components/molecules/transition";
import H1 from "../components/atoms/h1";
import { useTranslation } from "react-i18next";
import P1 from "../components/atoms/p1";
import Button from "../components/molecules/button";

const UserContext = createContext<User | null>(null);

export function useUser() {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
}

interface UserProviderProps {
  children: ReactNode;
}

export function UserContextProvider({ children }: UserProviderProps) {
  const [user, setUser] = useState<User | null>(null);
  const [isError, setIsError] = useState(false);
  const [isLoadingUser, setIsLoadingUser] = useState(true);

  const { get } = useApi();
  const { user: firebaseUser } = useAuth();
  const { t } = useTranslation();

  useEffect(() => {
    get<User>(`/users/${firebaseUser.uid}`)
      .then((user) => {
        setUser(user);
      })
      .catch(() => {
        setIsError(true);
      })
      .finally(() =>
        setTimeout(() => {
          setIsLoadingUser(false);
        }, 1000)
      );
  }, [firebaseUser.uid, get]);

  if (user?.stripe_session_url) {
    return (
      <Transition transitionKey="unpaid-user">
        <FullScreen>
          <Card width="500px">
            <H1>{t("Payment error")}</H1>
            <P1 width="">
              {t(
                "We are not able to verify your payment. Use the button below to continue to the secure payment portal."
              )}
            </P1>
            <ButtonContainer>
              <Button
                text={t("Payment portal")}
                onClick={() => (window.location.href = user.stripe_session_url)}
              />
            </ButtonContainer>
          </Card>
        </FullScreen>
      </Transition>
    );
  }

  if (isLoadingUser && !isError) {
    return (
      <Transition transitionKey="loading-user">
        <FullScreen>
          <PageState state="loading" />
        </FullScreen>
      </Transition>
    );
  }

  if (!isLoadingUser && isError) {
    return (
      <Transition transitionKey="error-user">
        <FullScreen>
          <PageState
            state="error"
            errorText="A critical error occurred while loading the Leads Beasts platform. Please try again later."
          />
        </FullScreen>
      </Transition>
    );
  }

  return (
    <Transition transitionKey="user-context">
      <UserContext.Provider value={user}>
        {user && children}
      </UserContext.Provider>
    </Transition>
  );
}
