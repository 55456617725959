import { FC, useEffect } from "react";

export type useRunOnceProps = {
  fn: () => any;
};

const useRunOnce: FC<useRunOnceProps> = ({ fn }) => {
  useEffect(() => {
    fn();

    // eslint-disable-next-line
  }, []);

  return null;
};

export default useRunOnce;
