import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.basic.filled[300]};
  border-top: 1px solid ${({ theme }) => theme.colors.basic.filled[300]};
  display: flex;
  flex-direction: column;
`;

export const Placeholder = styled.div`
  height: 16px;
  width: 100%;
`;
