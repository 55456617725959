import { FC, useEffect, useState } from "react";
import { Wrapper } from "./settings.style";
import { AccountProps } from "./settings.types";
import { ReactComponent as Globe } from "../../../media/icons/globe-2.svg";
import { ReactComponent as CreditCard } from "../../../media/icons/credit-card-outline.svg";
import { useTranslation } from "react-i18next";

import Select from "../../molecules/select";

import P1 from "../../atoms/p1";
import SettingsCard from "../../molecules/settings-card/settings-card";

import languages from "./constants/languages";
import { useUser } from "../../../contexts/user-context";
import { useApi } from "../../../contexts/api-context";
import Transition from "../../molecules/transition";
import { PageStateContainer } from "../../../styles/commons.styles";
import PageState from "../../organisms/page-state";

const Account: FC<AccountProps> = () => {
  const [language, setLanguage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);

  const { t } = useTranslation();
  const { id, email } = useUser();
  const { post } = useApi();

  useEffect(() => {
    setTimeout(() => {
      setIsPageLoading(false);
    }, 1000);
  }, [setIsPageLoading]);

  async function postLanguage() {
    try {
      setIsLoading(true);
      await post(`/users/${id}/language`, {
        language: language,
      });
      window.location.reload();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Transition transitionKey="settings">
      {isPageLoading ? (
        <PageStateContainer>
          <PageState state="loading" />
        </PageStateContainer>
      ) : (
        <Wrapper>
          <SettingsCard
            Icon={Globe}
            headerLabel={t("Language")}
            buttonLabel={t("Change")}
            onClick={() => postLanguage()}
            disabled={isLoading}
          >
            <Select
              label={t("Language")}
              options={languages}
              id="language-select"
              value={language}
              onChange={(e) => setLanguage(e.target.value)}
              disabled={isLoading}
            />
          </SettingsCard>
          <SettingsCard
            Icon={CreditCard}
            headerLabel={t("Subscription")}
            buttonLabel={t("Manage")}
            onClick={() =>
              window.open(
                `https://billing.stripe.com/p/login/test_cN26qk33X5JO3Di7ss?prefilled_email=${email}`,
                "_blank"
              )
            }
            disabled={isLoading}
          >
            <P1 width="fit-content">
              {t("Your payment details are managed in a secure Stripe portal.")}
            </P1>
          </SettingsCard>
        </Wrapper>
      )}
    </Transition>
  );
};

export default Account;
