import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const Wrapper = styled(NavLink)`
  padding: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #fff;
  border-bottom: 1px solid ${({ theme }) => theme.colors.basic.filled[300]};
  text-decoration: none;
  gap: 8px;
  position: relative;

  path {
    fill: ${({ theme }) => theme.typography.colors.hint};
  }

  * {
    transition: none;
  }

  :hover {
    background: ${({ theme }) => theme.colors.basic.filled[200]};
  }

  &.active {
    background-color: ${({ theme }) => theme.colors.primary.transparent[0.08]};

    ::before {
      content: "";
      width: 4px;
      height: 100%;
      background-color: ${({ theme }) => theme.colors.primary.filled[500]};
      position: absolute;
      left: 0;
    }

    p {
      color: ${({ theme }) => theme.colors.primary.filled[500]};
    }

    path {
      fill: ${({ theme }) => theme.colors.primary.filled[500]};
    }
  }
`;
