import { FC } from "react";
import { Wrapper, Logo } from "./vertical-navigation.style";
import { VerticalNavigationProps } from "./vertical-navigation.types";
import logo from "../../../media/images/logo.png";
import NavHeader from "../../molecules/nav-header";
import VerticalNavItem from "../../molecules/vertical-nav-item";
import useNavItems from "./use-nav-items";
import { useUser } from "../../../contexts/user-context";

const VerticalNavigation: FC<VerticalNavigationProps> = () => {
  const user = useUser();
  const routes = useNavItems();

  return (
    <Wrapper>
      <Logo src={logo} alt="logo" />
      <NavHeader email={user.email} />
      {routes.map((route) => (
        <VerticalNavItem
          key={route.id}
          label={route.label}
          icon={route.icon}
          path={route.path}
          end={route.end}
        />
      ))}
    </Wrapper>
  );
};

export default VerticalNavigation;
