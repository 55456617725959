import { FC } from "react";
import { MailProps } from "./mail.types";
import SubNav from "../../molecules/sub-nav";
import { Outlet } from "react-router-dom";
import MailOnboarding from "../mail-onboarding/mail-onboarding";
import { useUser } from "../../../contexts/user-context";

const Mail: FC<MailProps> = () => {
  const user = useUser();

  if (
    user.sendgrid_verification_status &&
    user.sendgrid_verification_status === "completed"
  ) {
    return (
      <>
        <SubNav />
        <Outlet />
      </>
    );
  }

  if (user.sendgrid_verification_status !== "completed") {
    return <MailOnboarding />;
  }

  return <div />;
};

export default Mail;
