import { Toaster as HotToaster } from "react-hot-toast";
import theme from "../../styles/theme";

const Toaster = () => (
  <HotToaster
    position="top-center"
    gutter={8}
    toastOptions={{
      // Define default options
      className: "",
      duration: 5000,
      style: {
        background: theme.colors.basic.filled[100],
        color: theme.typography.colors.default,
        fontFamily: theme.typography.family,
        fontSize: theme.typography.p1.size,
        borderRadius: theme.borderRadius,
      },

      error: {
        style: {
          backgroundColor: theme.colors.danger.filled[500],
          color: theme.typography.colors.alternative,
        },
      },

      // Default options for specific types
      success: {
        duration: 3000,
      },
    }}
  />
);

export default Toaster;
