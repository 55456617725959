import { FC, useMemo, useState } from "react";
import { SignUpProps } from "./sign-up.types";
import { Card, Column, FullScreen } from "../../../styles/commons.styles";
import logo from "../../../media/images/logo.png";
import H1 from "../../atoms/h1";
import S1 from "../../atoms/s1";
import { useTranslation } from "react-i18next";
import Form from "../../molecules/form";
import * as Yup from "yup";
import YupPassword from "yup-password";
import { FieldProps } from "../../molecules/field/field.types";
import { useNavigate, useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import PageState from "../../organisms/page-state";

YupPassword(Yup);

const SignUp: FC<SignUpProps> = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const validationSchema = useMemo(
    () =>
      Yup.object({
        email: Yup.string()
          .email("Invalid email address.")
          .required("This field is required."),
        password: Yup.string()
          .max(30, "Must be 30 characters or less.")
          .min(8, "Must be 8 characters minimum.")
          .required("This field is required.")
          .minLowercase(
            1,
            "Password must contain at least 1 lower case letter."
          )
          .minUppercase(
            1,
            "Password must contain at least 1 upper case letter."
          )
          .minNumbers(1, "Password must contain at least 1 number."),
        confirmPassword: Yup.string()
          .required("This field is required.")
          .oneOf([Yup.ref("password")], "Passwords must match.")
          .required("This field is required."),
      }),
    []
  );

  const fields = useMemo(
    () =>
      [
        {
          label: "email",
          placeholder: "Enter your email",
          mode: "input",
          name: "email",
          required: true,
        },
        {
          label: "password",
          placeholder: "Enter your password",
          type: "password",
          mode: "input",
          name: "password",
          required: true,
        },
        {
          label: "confirm password",
          placeholder: "Confirm your password",
          type: "password",
          mode: "input",
          name: "confirmPassword",
          required: true,
        },
        {
          mode: "radio-enhanced",
          name: "subscription",
          label: "subscription",
          options: [
            {
              icon: "gift-outline",
              title: "Freemium",
              caption: "Free",
              value: "freemium",
              id: "freemium",
            },
            {
              icon: "trending-up-outline",
              title: "Standard",
              caption: "600 yearly",
              value: "standard_monthly",
              id: "standard_monthly",
            },
            {
              icon: "award-outline",
              title: "Premium",
              caption: "1200 yearly",
              value: "premium_monthly",
              id: "premium_monthly",
            },
            {
              icon: "trending-up-outline",
              title: "Standard",
              caption: "600 montlhy",
              value: "standard_yearly",
              id: "standard_yearly",
            },
            {
              icon: "award-outline",
              title: "Premium",
              caption: "1200 montlhy",
              value: "premium_yearly",
              id: "premium_yearly",
            },
          ],
        },
      ] as FieldProps[],
    []
  );

  async function signUp({
    email,
    password,
    subscription,
  }: {
    [key: string]: any;
  }) {
    try {
      setIsLoading(true);
      localStorage.setItem("subscription", subscription);
      await createUserWithEmailAndPassword(getAuth(), email, password);
    } catch (error) {
      toast.error(
        t("Something went wrong during sign up. Please try again later.")
      );
    }
  }

  return (
    <FullScreen>
      {isLoading ? (
        <Card width="700px">
          <PageState state="payment" />
        </Card>
      ) : (
        <Card width="500px">
          <Column>
            <Column gap="0">
              <img alt="logo" width={200} src={logo} />
              <H1>{t("Sign up")}</H1>
              <S1>{t("Sign up to grow your business")}</S1>
            </Column>
            <Form
              initialValues={{
                email: "",
                password: "",
                confirmPassword: "",
                subscription: searchParams.get("subscription") ?? "",
              }}
              validationSchema={validationSchema}
              onSubmit={signUp}
              fields={fields}
              actionButtonText={t("Sign up")}
              cancelButtonAction={() => navigate("/")}
            />
          </Column>
        </Card>
      )}
    </FullScreen>
  );
};

export default SignUp;
