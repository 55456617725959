import { FC } from "react";
import { Wrapper, Image } from "./not-found.style";
import { NotFoundProps } from "./not-found.types";
import image from "../../../media/images/not-found.svg";
import H2 from "../../atoms/h2";
import { useTranslation } from "react-i18next";

const NotFound: FC<NotFoundProps> = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Image src={image} />
      <H2>{t("Page not found")}</H2>
    </Wrapper>
  );
};

export default NotFound;
