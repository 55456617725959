import { Navigate, createBrowserRouter } from "react-router-dom";
import Settings from "../components/pages/settings/settings";
import Leads from "../components/pages/leads";
import Searches from "../components/pages/searches";
import Mail from "../components/pages/mail";
import MailOverview from "../components/pages/mail-overview";
import MailLists from "../components/pages/mail-lists";
import MailTemplates from "../components/pages/mail-templates";
import MailDns from "../components/pages/mail-dns";
import MailListDetails from "../components/pages/mail-list-details";
import SearchDetails from "../components/pages/search-details";
import SearchesOverview from "../components/pages/searches-overview";
import MailOnboarding from "../components/pages/mail-onboarding";
import NotFound from "../components/pages/not-found";
import SignIn from "../components/pages/sign-in";
import SignUp from "../components/pages/sign-up";
import ResetPassword from "../components/pages/reset-password";
import PrimaryLayout from "../components/layouts/primary-layout";

export const authenticatedRoutes = createBrowserRouter([
  {
    path: "/",
    element: <PrimaryLayout />,
    children: [
      {
        path: "/",
        element: <Navigate to="/leads" />,
      },
      {
        path: "mail",
        element: <Mail />,
        children: [
          {
            path: "overview",
            element: <MailOverview />,
          },
          {
            path: "lists",
            element: <MailLists />,
          },
          {
            path: "lists/:listId",
            element: <MailListDetails />,
          },
          {
            path: "templates",
            element: <MailTemplates />,
          },
          {
            path: "dns",
            element: <MailDns />,
          },
        ],
      },
      {
        path: "onboarding",
        element: <MailOnboarding />,
      },
      {
        path: "searches",
        element: <Searches />,
        children: [
          {
            path: "overview",
            element: <SearchesOverview />,
          },
          {
            path: "overview/:searchId",
            element: <SearchDetails />,
          },
        ],
      },
      {
        path: "settings",
        element: <Settings />,
      },
      {
        path: "/leads",
        element: <Leads />,
      },
      {
        path: "/*",
        element: <NotFound />,
      },
    ],
  },
]);

export const unauthenticatedRoutes = createBrowserRouter([
  {
    path: "/",
    element: <SignIn />,
  },
  {
    path: "/sign-up",
    element: <SignUp />,
  },
  {
    path: "/reset-password",
    element: <ResetPassword />,
  },
  {
    path: "/*",
    element: <Navigate to="/" />,
  },
]);
