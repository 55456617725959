import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 60px;
  height: 100%;
`;

export const Image = styled.img`
  height: 100%;
`;

export const ImageContainer = styled.div`
  height: 300px;
`;

export const ErrorMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

export const TextContainer = styled.div``;
