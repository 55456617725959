import styled from "styled-components";
import { Card } from "../../../styles/commons.styles";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
`;

export const Image = styled.img`
  width: 100%;
`;

export const StartContainer = styled.div`
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  text-align: center;
`;

export const OnboardingCard = styled(Card)`
  max-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StepperContainer = styled.div`
  position: absolute;
  left: 100px;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
