import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 30px;
  display: flex;
  gap: 30px;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.basic.filled[100]};
  border-radius: ${({ theme }) => theme.borderRadius};
  border: 1px solid ${({ theme }) => theme.colors.basic.filled[300]};

  h4 {
    color: ${({ theme }) => theme.colors.primary.filled[500]};
  }
`;

export const IconBackground = styled.div`
  background-color: ${({ theme }) => theme.colors.primary.transparent[0.24]};
  width: 60px;
  height: 60px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
