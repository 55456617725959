import { FC } from "react";
import { Display, Wrapper } from "./checkbox.style";
import { CheckboxProps } from "./checkbox.types";
import Icon from "../icon";
import Label from "../../atoms/label";
import theme from "../../../styles/theme";

const Checkbox: FC<CheckboxProps> = ({ label, checked, onClick }) => {
  return (
    <Wrapper>
      <input type="checkbox" />
      <Display onClick={onClick}>
        {checked && (
          <Icon
            Shape="checkmark-outline"
            color={theme.colors.primary.filled[500]}
          />
        )}
      </Display>
      {label && <Label onClick={onClick}>{label}</Label>}
    </Wrapper>
  );
};

export default Checkbox;
