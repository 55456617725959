import { FC } from "react";
import { Wrapper, StyledInput } from "./input.style";
import { InputProps } from "./input.types";

import Label from "../../atoms/label";

const Input: FC<InputProps> = ({
  label,
  value,
  onChange,
  disabled,
  placeholder,
  id,
  onEnter,
  list,
  onFocus,
  onBlur,
  type,
}) => {
  return (
    <Wrapper>
      {label && <Label htmlFor={id}>{label}</Label>}
      <StyledInput
        id={id}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        disabled={disabled}
        placeholder={placeholder}
        onKeyDown={onEnter}
        list={list}
        onFocus={onFocus}
        onBlur={onBlur}
        type={type}
      />
    </Wrapper>
  );
};

export default Input;
