import styled from "styled-components";
import S2 from "../../atoms/s2";

interface WrapperProps {
  selected: boolean;
  disabled: boolean;
}

export const EllipsisS2 = styled(S2)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.colors.basic.filled[200] : theme.colors.basic.filled[100]};
  border-radius: ${({ theme }) => theme.borderRadius};
  cursor: pointer;
  width: 150px;

  border-style: solid;
  border-color: ${({ theme, selected, disabled }) => {
    if (selected && !disabled) {
      return theme.colors.primary.filled[500];
    }
    if (disabled && !selected) {
      return theme.colors.basic.filled[200];
    }
    if (disabled && selected) {
      return theme.colors.basic.filled[400];
    }
    return theme.colors.basic.filled[300];
  }};

  border-width: 1px;

  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

  :hover {
    border: ${({ theme, selected }) =>
      selected ? "" : `1px solid ${theme.colors.basic.filled[400]}`};
    background-color: ${({ theme, selected }) =>
      selected ? "" : theme.colors.basic.filled[200]};
  }
`;

export const Header = styled.div`
  padding: 30px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.basic.filled[300]};
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Footer = styled.div`
  padding: 20px;
`;
