import { FC } from "react";
import { Wrapper, NavItem } from "./sub-nav.style";
import { SubNavProps } from "./sub-nav.types";
import Label from "../../atoms/label";

const items = [
  {
    id: 899,
    label: "Overview",
    path: "overview",
  },
  {
    id: 898,
    label: "Lists",
    path: "lists",
  },
  {
    id: 897,
    label: "Templates",
    path: "templates",
  },
];

const SubNav: FC<SubNavProps> = () => {
  return (
    <Wrapper>
      {items.map((item) => (
        <NavItem key={item.id} to={`${item.path}`}>
          <Label>{item.label}</Label>
        </NavItem>
      ))}
    </Wrapper>
  );
};

export default SubNav;
