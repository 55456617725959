import {
  createContext,
  useContext,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from "react";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { useAuth } from "./auth-context";

interface ApiContextProps {
  get: <T>(url: string, requestConfig?: AxiosRequestConfig) => Promise<T>;
  post: <T>(url: string, data: object) => Promise<T>;
  patch: (url: string, data: any) => Promise<void>;
  deleteResource: (
    url: string,
    requestConfig?: AxiosRequestConfig
  ) => Promise<void>;
}

const ApiContext = createContext<ApiContextProps | null>(null);

export function useApi() {
  const context = useContext(ApiContext);

  if (!context) {
    throw new Error("useApi must be used within an ApiContextProvider");
  }

  return context;
}

interface ApiContextProviderProps {
  children: ReactNode;
}

export function ApiContextProvider({ children }: ApiContextProviderProps) {
  const [isApiInitialised, setIsApiInitialised] = useState(false);
  const axiosInstance = useMemo(
    () =>
      axios.create({
        baseURL: process.env.REACT_APP_BASE_URL,
        timeout: 5000,
      }),
    []
  );

  const { user, getIdToken } = useAuth();

  useEffect(() => {
    axiosInstance.interceptors.request.use(async (config) => {
      const idToken = await getIdToken();
      config.headers["Authorization"] = `Bearer ${idToken}`;
      config.url = config.url?.replace(":userId", user.uid);
      return config;
    });
    setIsApiInitialised(true);
  }, [axiosInstance.interceptors.request, getIdToken, user.uid]);

  async function get<T>(
    url: string,
    requestConfig: AxiosRequestConfig = {}
  ): Promise<T> {
    try {
      const response: AxiosResponse<T> = await axiosInstance.get(
        url,
        requestConfig
      );
      return response.data;
    } catch (error) {
      throw handleError(error as AxiosError);
    }
  }

  async function post<T>(url: string, data: object): Promise<T> {
    try {
      const response: AxiosResponse<T> = await axiosInstance.post(url, data);
      return response.data;
    } catch (error) {
      throw handleError(error as AxiosError);
    }
  }

  async function patch(url: string, data: any): Promise<void> {
    try {
      const response: AxiosResponse = await axiosInstance.patch(url, data);
      return response.data;
    } catch (error) {
      throw handleError(error as AxiosError);
    }
  }

  async function deleteResource(
    url: string,
    requestConfig: AxiosRequestConfig = {}
  ): Promise<void> {
    try {
      const response: AxiosResponse = await axiosInstance.delete(
        url,
        requestConfig
      );
      return response.data;
    } catch (error) {
      throw handleError(error as AxiosError);
    }
  }

  function handleError(error: AxiosError) {
    if (error.response) {
      // The request was made, but the server responded with an error status code
      console.error("Response Error:", error.response.data);
      throw new Error(
        "Request failed with status code " + error.response.status
      );
    } else if (error.request) {
      // The request was made, but no response was received
      console.error("Request Error:", error.request);
      throw new Error("No response received from the server");
    } else {
      // Something happened in setting up the request that triggered an error
      console.error("Request Setup Error:", error.message);
      throw new Error("Request setup failed");
    }
  }

  const value = {
    get,
    post,
    patch,
    deleteResource,
  };

  return (
    <ApiContext.Provider value={value}>
      {isApiInitialised && children}
    </ApiContext.Provider>
  );
}
