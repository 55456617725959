import styled from "styled-components";
import TextProps from "../../types/text-props";

export const H5 = styled.h5<TextProps>`
  font-family: ${({ theme }) => theme.typography.family};
  font-weight: ${({ theme }) => theme.typography.h5.weight};
  font-size: ${({ theme }) => theme.typography.h5.size};
  line-height: ${({ theme }) => theme.typography.h5.lineHeight};
  color: ${({ theme, color }) => color ?? theme.typography.colors.default};
  margin: 0;
  width: ${({ width }) => width ?? "max-content"};
`;

export default H5;
