import { FC } from "react";
import { Header, Body } from "./modal.style";
import { ModalProps } from "./modal.types";
import ReactModal from "react-modal";
import H1 from "../../atoms/h1";
import theme from "../../../styles/theme";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.25)",
    borderRadius: "4px",
    backgroundColor: theme.colors.basic.filled[100],
    border: "none",
    padding: "40px",
    width: "500px",
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
ReactModal.setAppElement("#root");

const Modal: FC<ModalProps> = ({ modalIsOpen, headerText, children }) => {
  return (
    <ReactModal
      isOpen={modalIsOpen}
      style={customStyles}
      contentLabel="Confirmation Modal"
    >
      <Header>
        <H1 width="">{headerText}</H1>
      </Header>
      <Body>{children}</Body>
    </ReactModal>
  );
};

export default Modal;
