import { FC } from "react";
import { Wrapper, IconBackground } from "./stat-card.style";
import { StatCardProps } from "./stat-card.types";
import { Icon } from "@iconify/react";
import H4 from "../../atoms/h4";
import S1 from "../../atoms/s1";
import theme from "../../../styles/theme";

const StatCard: FC<StatCardProps> = ({ icon, stat, description }) => {
  return (
    <Wrapper>
      <IconBackground>
        <Icon
          icon={`eva:${icon}`}
          color={theme.colors.primary.filled[500]}
          width="32px"
        />
      </IconBackground>
      <div>
        <H4>{stat}</H4>
        <S1>{description}</S1>
      </div>
    </Wrapper>
  );
};

export default StatCard;
