import { FC } from "react";
import { Wrapper, Options, Option, InputContainer } from "./datalist.style";
import { DatalistProps } from "./datalist.types";
import Input from "../input";
import { MoonLoader } from "react-spinners";
import theme from "../../../styles/theme";

const Datalist: FC<DatalistProps> = ({
  label,
  placeholder,
  value,
  onChange,
  id,
  disabled,
  options,
  onOptionClick,
  onKeyDown,
  isLoading,
  resetOptions,
}) => {
  return (
    <Wrapper>
      <InputContainer>
        <Input
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          disabled={disabled}
          id={id}
          label={label}
          list={`${label}-datalist`}
          onEnter={onKeyDown}
          onBlur={resetOptions}
        />
        <MoonLoader
          color={theme.colors.basic.filled[600]}
          speedMultiplier={0.6}
          loading={isLoading}
          size={16}
          cssOverride={{
            position: "absolute",
            right: "15px",
            top: "53%",
          }}
        />
      </InputContainer>

      {options.length > 0 && (
        <Options>
          {options.map((option) => (
            <Option key={option} onClick={() => onOptionClick(option)}>
              {option}
            </Option>
          ))}
        </Options>
      )}
    </Wrapper>
  );
};

export default Datalist;
