import styled from "styled-components";
import { IconWrapperProps } from "./icon.types";

export const IconWrapper = styled.div<IconWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ width }) => `${width ?? 32}px`};
  fill: ${({ mode, theme }) => (mode ? theme.colors[mode].filled[500] : "")};
`;
