import { FC } from "react";
import { FadeDiv } from "./transition.style";
import { TransitionProps } from "./transition.types";
import {
  SwitchTransition,
  Transition as ReactTransition,
} from "react-transition-group";

const Transition: FC<TransitionProps> = ({ transitionKey, children }) => {
  return (
    <SwitchTransition mode="out-in">
      <ReactTransition
        key={transitionKey}
        timeout={400}
        unmountOnExit
        mountOnEnter
      >
        {(state) => <FadeDiv state={state}>{children}</FadeDiv>}
      </ReactTransition>
    </SwitchTransition>
  );
};

export default Transition;
