import { FC, useState, useEffect } from "react";
import { LeadsProps, LeadsResponse } from "./leads.types";
import StatCard from "../../molecules/stat-card";
import { useTranslation } from "react-i18next";
import Input from "../../molecules/input";
import Select from "../../molecules/select";
import Button from "../../molecules/button";
import useSWR from "swr";
import Table from "../../molecules/table";
import columns from "./columns";
import FileSaver from "file-saver";
import {
  Container,
  PageStateContainer,
  PageWrapper,
} from "../../../styles/commons.styles";
import ConfirmationModal from "../../molecules/confirmation-modal";
import AddLeadsToMailListModal from "../../molecules/add-leads-to-mail-list-modal/add-leads-to-mail-list-modal";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useUser } from "../../../contexts/user-context";
import { useApi } from "../../../contexts/api-context";
import useDebounce from "../../../hooks/use-debounce";
import PageState from "../../organisms/page-state";
import Transition from "../../molecules/transition";
import useRunOnce from "../../../hooks/use-run-once";

interface MailListOption {
  id: string;
  name: string;
}

interface GetMailListsResponse {
  lists: MailListOption[];
}

const Leads: FC<LeadsProps> = () => {
  const [companyName, setCompanyName] = useState<string>("");
  const [location, setLocation] = useState<string>("");
  const [rowSelection, setRowSelection] = useState({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDeleteLeadsModalOpen, setIsDeleteLeadsModalOpen] = useState(false);
  const [isAddLeadsToMailListModalOpen, setIsAddLeadsToMailListModalOpen] =
    useState(false);
  const [mailLists, setMailLists] = useState<GetMailListsResponse>(
    {} as GetMailListsResponse
  );
  const [selectedList, setSelectedList] = useState("");
  const [selectedIndustry, setSelectedIndustry] = useState("");
  const [isLoadingLeads, setIsLoadingLeads] = useState(true);
  const [isLoadingMailLists, setIsLoadingMailLists] = useState(true);
  const [isPageError, setIsPageError] = useState(false);

  const [searchParams] = useSearchParams();

  const { id } = useUser();
  const { get, post, deleteResource } = useApi();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useRunOnce({
    fn: () =>
      get<GetMailListsResponse>(`/users/${id}/mail-lists`)
        .then((response) => setMailLists(response))
        .catch(() => setIsPageError(true))
        .finally(() => setIsLoadingMailLists(false)),
  });

  useEffect(() => {
    const list = searchParams.get("list");
    if (list) {
      setSelectedList(list);
    }
  }, [searchParams]);

  const { data: leads, mutate } = useSWR(id && "getLeads", getLeads, {
    refreshInterval: 600000,
    fallbackData: {
      leads: [],
      top_location: "",
      top_industry: "",
      number_of_leads: 0,
      distinct_locations: [],
      distinct_indutries: [],
    },
  });

  const handleCompanyNameChange = useDebounce(
    setCompanyName,
    setIsLoading,
    mutate
  );

  async function getLeads() {
    try {
      const res = await get<LeadsResponse>(`/users/${id}/leads`, {
        params: {
          company_name: companyName,
          location,
          industry: selectedIndustry,
        },
      });

      return res;
    } catch (error) {
      setIsPageError(true);
    } finally {
      setIsLoadingLeads(false);
    }
  }

  async function handleDownload() {
    setIsLoading(true);
    const response = await get<ArrayBuffer>(`/users/${id}/searches/file`, {
      params: {
        leads: Object.keys(rowSelection).join(","),
      },
    });

    FileSaver.saveAs(
      new Blob([response]),
      `${new Date().toLocaleDateString()}.csv`
    );

    setIsLoading(false);
    setRowSelection({});
  }

  async function deleteLeads() {
    setIsLoading(true);
    await deleteResource(`/users/${id}/leads`, {
      params: {
        leads: Object.keys(rowSelection).join(","),
      },
    });
    await mutate();
    setIsLoading(false);
    setRowSelection({});
  }

  async function addLeadsToMailList() {
    setIsLoading(true);
    await post(`/users/${id}/mail-lists/${selectedList}/contacts`, {
      leads: Object.keys(rowSelection).join(","),
    });
    await mutate();
    setIsLoading(false);
    setIsAddLeadsToMailListModalOpen(false);
    setRowSelection({});
  }

  return (
    <Transition
      transitionKey={isLoadingLeads && isLoadingMailLists ? "loader" : "page"}
    >
      {isLoadingLeads && isLoadingMailLists ? (
        <PageStateContainer>
          <PageState state="loading" />
        </PageStateContainer>
      ) : isPageError ? (
        <PageStateContainer>
          <PageState
            state="error"
            errorText={t(
              "We weren't able to load your leads. Please try again later."
            )}
          />
        </PageStateContainer>
      ) : (
        <PageWrapper>
          <Container>
            <StatCard
              icon="award-outline"
              stat={leads!.number_of_leads}
              description={t("Leads found")}
            />
            {leads!.top_industry && (
              <StatCard
                icon="pin-outline"
                stat={leads!.top_location}
                description={t("Top location")}
              />
            )}
            {leads!.top_location && (
              <StatCard
                icon="briefcase-outline"
                stat={leads!.top_industry}
                description={t("Top industry")}
              />
            )}
          </Container>
          <Container>
            <Input
              placeholder={t("Enter company name")}
              id="search"
              onChange={handleCompanyNameChange}
              value={companyName}
              label={t("company name")}
              disabled={isLoading}
            />
            {leads && leads.distinct_locations && (
              <Select
                id="location-select"
                label={t("location")}
                placeholder={t("Select location")}
                value={location}
                onChange={(e) => {
                  setLocation(e.target.value);
                  setTimeout(async () => {
                    await mutate();
                  }, 750);
                }}
                disabled={isLoading}
                options={leads!.distinct_locations.map((location) => ({
                  label: location,
                  value: location,
                }))}
              />
            )}
            {leads && leads.distinct_indutries && (
              <Select
                id="industry-select"
                label={t("industry")}
                placeholder={t("Select industry")}
                value={selectedIndustry}
                onChange={(e) => {
                  setSelectedIndustry(e.target.value);
                  setTimeout(async () => {
                    await mutate();
                  }, 750);
                }}
                disabled={isLoading}
                options={leads!.distinct_indutries.map((industry) => ({
                  label: industry,
                  value: industry,
                }))}
              />
            )}
            <Button
              onClick={handleDownload}
              disabled={Object.keys(rowSelection).length <= 0 || isLoading}
              mode="warning"
              text={t("download")}
            />
            <Button
              onClick={() => setIsAddLeadsToMailListModalOpen(true)}
              disabled={Object.keys(rowSelection).length <= 0 || isLoading}
              mode="info"
              text={t("Add to mail list")}
            />
            <Button
              onClick={() => setIsDeleteLeadsModalOpen(true)}
              disabled={Object.keys(rowSelection).length <= 0 || isLoading}
              mode="danger"
              text={t("delete")}
            />
          </Container>
          <Container>
            {leads && leads.leads && (
              <Table
                data={leads!!.leads}
                columns={columns}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                emptyTableTitle={t("You don't have any leads yet!")}
                emptyTableSubTitle={t("Find some leads using the button below")}
                emptyTableButtonClick={() => navigate("/searches/overview")}
                emptyTableButtonText={t("Find leads")}
              />
            )}
          </Container>
          {mailLists && mailLists.lists && (
            <AddLeadsToMailListModal
              modalIsOpen={isAddLeadsToMailListModalOpen}
              closeModal={() => setIsAddLeadsToMailListModalOpen(false)}
              userId={id}
              listOptions={mailLists.lists}
              addLeadsToMailList={addLeadsToMailList}
              isLoading={isLoading}
              setSelectedList={setSelectedList}
              selectedList={selectedList}
            />
          )}
          <ConfirmationModal
            modalIsOpen={isDeleteLeadsModalOpen}
            onConfirm={deleteLeads}
            closeModal={() => setIsDeleteLeadsModalOpen(false)}
            headerText={t("Delete leads")}
            bodyText={t("Are you sure you want to delete these leads?")}
          />
        </PageWrapper>
      )}
    </Transition>
  );
};

export default Leads;
