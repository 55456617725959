import styled from "styled-components";

interface FadeDivProps {
  state: string;
}

export const FadeDiv = styled.div<FadeDivProps>`
  transition: 400ms;
  opacity: ${({ state }) => (state === "entered" ? 1 : 0)};
  display: ${({ state }) => (state === "exited" ? "none" : "block")};
`;
