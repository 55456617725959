import styled, { css } from "styled-components";
import TextProps from "../../types/text-props";

interface LabelProps extends TextProps {
  required?: boolean;
}

const requiredElement = css`
  &:after {
    content: "*";
    color: ${({ theme }) => theme.colors.danger.filled[300]};
    margin-left: 5px;
  }
`;

export const Label = styled.label<LabelProps>`
  font-family: ${({ theme }) => theme.typography.family};
  font-weight: ${({ theme }) => theme.typography.label.weight};
  font-size: ${({ theme }) => theme.typography.label.size};
  line-height: ${({ theme }) => theme.typography.label.lineHeight};
  color: ${({ theme, color }) => color ?? theme.typography.colors.hint};
  margin: 0;
  width: ${({ width }) => width ?? "max-content"};
  text-transform: uppercase;

  ${({ required }) => required && requiredElement}
`;

export default Label;
