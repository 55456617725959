import { FC } from "react";
import { IconWrapper } from "./icon.style";
import { IconProps } from "./icon.types";
import { Icon as Iconify } from "@iconify/react";

const Icon: FC<IconProps> = ({ Shape, color, size }) => {
  return (
    <>
      {typeof Shape === "string" ? (
        <Iconify icon={`eva:${Shape}`} color={color} width={size} />
      ) : (
        <IconWrapper width={size} color={color}>
          <Shape />
        </IconWrapper>
      )}
    </>
  );
};

export default Icon;
