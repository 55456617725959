import { FC } from "react";
import { Wrapper, Header, Footer, EllipsisS2 } from "./file-card.style";
import { FileCardProps } from "./file-card.types";
import { Icon } from "@iconify/react";
import theme from "../../../styles/theme";

const FileCard: FC<FileCardProps> = ({
  fileName,
  creationDate,
  selected,
  onClick,
  disabled,
}) => {
  return (
    <Wrapper disabled={disabled} onClick={onClick} selected={selected}>
      <Header>
        <Icon
          icon="eva:file-outline"
          color={
            disabled
              ? theme.colors.basic.filled[500]
              : theme.colors.primary.filled[500]
          }
          width={48}
        />
      </Header>
      <Footer>
        <EllipsisS2>{fileName}</EllipsisS2>
        <EllipsisS2>{creationDate}</EllipsisS2>
      </Footer>
    </Wrapper>
  );
};

export default FileCard;
