import { FC } from "react";
import { BaseButton } from "./button.style";
import { ButtonProps } from "./button.types";
import { BarLoader } from "react-spinners";
import theme from "../../../styles/theme";
import Icon from "../icon";

const Button: FC<ButtonProps> = ({
  text,
  onClick,
  type,
  disabled,
  icon,
  mode,
  variant,
  size,
  isLoading,
  width,
}) => {
  function getMode(
    buttonVariant: string,
    disabled: boolean,
    buttonMode: "basic" | "primary" | "danger" | "warning" | "success" | "info"
  ): string {
    if (disabled) {
      return theme.colors.basic.filled[500];
    }

    if (buttonVariant === "filled") {
      return theme.colors.basic.filled[100];
    }

    if (buttonVariant === "outline" || buttonVariant === "ghost") {
      return theme.colors[buttonMode].filled[500];
    }

    return theme.colors.basic.filled[100];
  }

  return (
    <BaseButton
      disabled={disabled}
      type={type ?? "button"}
      onClick={onClick}
      mode={mode ?? "primary"}
      variant={variant ?? "filled"}
      size={size ?? "medium"}
      width={width}
    >
      {isLoading ? (
        <BarLoader color={theme.colors.basic.filled[500]} />
      ) : (
        <>
          {icon && (
            <Icon
              Shape={icon}
              color={getMode(
                variant ?? "filled",
                disabled ?? false,
                mode ?? "primary"
              )}
              size={18}
            />
          )}
          {text}
        </>
      )}
    </BaseButton>
  );
};

export default Button;
