import styled, { css } from "styled-components";
import P1 from "../../atoms/p1";

interface InputProps {
  error: boolean;
}

const inputStyles = css`
  border: 1px solid ${({ theme }) => theme.colors.basic.filled[400]};
  border-radius: 4px;
  padding: 10px 16px;
  outline: none;
  height: 40px;
  font-family: ${({ theme }) => theme.typography.family};

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #8f9bb3;
    opacity: 1; /* Firefox */
  }

  ::-webkit-datetime-edit {
    color: #8f9bb3;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #8f9bb3;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #8f9bb3;
  }

  :disabled {
    background-color: ${({ theme }) => theme.colors.basic.filled[200]};
    border-color: ${({ theme }) => theme.colors.basic.filled[400]};
    color: ${({ theme }) => theme.colors.basic.filled[500]};
  }

  :hover {
    border-color: ${({ theme }) => theme.colors.basic.filled[500]};
  }

  :focus {
    border-color: ${({ theme }) => theme.colors.basic.filled[600]};
  }
`;

export const Wrapper = styled.div<InputProps>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: flex-start;
  position: relative;

  input {
    ${inputStyles}
  }

  input,
  select {
    border: 1px solid
      ${({ error, theme }) =>
        error
          ? theme.colors.danger.filled[500]
          : theme.colors.basic.filled[400]};
  }
`;

export const ErrorMessage = styled(P1)`
  position: absolute;
  color: ${({ theme }) => theme.colors.danger.filled[500]};
  font-size: 12px;
  top: 0;
  right: 0;
`;

export const RadioInputGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 30px;
`;
