import { FC } from "react";
import { ModalBody, ModalFooter } from "./add-leads-to-mail-list-modal.style";
import { AddLeadsToMailListModalProps } from "./add-leads-to-mail-list-modal.types";
import Modal from "react-modal";
import H2 from "../../atoms/h2";
import { useTranslation } from "react-i18next";
import Select from "../select/select";
import Button from "../button";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.25)",
    borderRadius: "4px",
    backgroundColor: "#FFFFFF",
    border: "none",
    padding: "40px",
    width: "500px",
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

const AddLeadsToMailListModal: FC<AddLeadsToMailListModalProps> = ({
  modalIsOpen,
  closeModal,
  listOptions,
  addLeadsToMailList,
  isLoading,
  selectedList,
  setSelectedList,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={modalIsOpen}
      style={customStyles}
      contentLabel="Confirmation Modal"
    >
      <H2>{t("Add to a mail list")}</H2>
      <ModalBody>
        <Select
          value={selectedList}
          onChange={(e) => setSelectedList(e.target.value)}
          placeholder={t("Select a list name")}
          disabled={isLoading}
          id={"add-list-name-input"}
          label={t("List name")}
          options={listOptions.map((list) => ({
            value: list.id,
            label: list.name,
          }))}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          mode="danger"
          variant="outline"
          onClick={() => closeModal()}
          text={t("Cancel")}
        />
        <Button variant="filled" onClick={addLeadsToMailList} text={t("Add")} />
      </ModalFooter>
    </Modal>
  );
};

export default AddLeadsToMailListModal;
