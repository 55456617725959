import styled from "styled-components";

export const Card = styled.div`
  padding: 30px;
  gap: 20px;
  justify-content: space-between;
  height: fit-content;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.basic.filled[100]};
  border-radius: ${({ theme }) => theme.borderRadius};
  border: 1px solid ${({ theme }) => theme.colors.basic.filled[300]};
`;

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  svg {
    fill: #6eba53;
    width: 32px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
