import { FC } from "react";
import { Wrapper, StyledSelect } from "./select.style";
import { SelectProps, OptionsTypes } from "./select.types";

import Label from "../../atoms/label";

const Select: FC<SelectProps> = ({
  label,
  options,
  value,
  onChange,
  placeholder,
  disabled,
  id,
}) => {
  return (
    <Wrapper>
      {label && <Label htmlFor={id}>{label}</Label>}
      <StyledSelect
        id={id}
        value={value}
        onChange={onChange}
        disabled={disabled}
        name={id}
      >
        {placeholder && <option value="">{placeholder}</option>}
        {options.map((option: OptionsTypes) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </StyledSelect>
    </Wrapper>
  );
};

export default Select;
