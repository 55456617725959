import { FC, useState, useEffect } from "react";
import {
  Wrapper,
  Image,
  StartContainer,
  OnboardingCard,
  ButtonGroup,
  StepperContainer,
  TextContainer,
} from "./mail-onboarding.style";
import { MailOnboardingProps } from "./mail-onboarding.types";
import { useTranslation } from "react-i18next";
import H4 from "../../atoms/h4";
import Button from "../../molecules/button";
import image from "../../../media/images/start.svg";
import P1 from "../../atoms/p1";
import Input from "../../molecules/input/input";
import StepperItem from "../../molecules/stepper-item";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../../contexts/user-context";
import { useApi } from "../../../contexts/api-context";
import S1 from "../../atoms/s1";

const MailOnboarding: FC<MailOnboardingProps> = () => {
  const [domain, setDomain] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [mailToSendInsructionsTo, setMailToSendInsructionsTo] = useState("");
  const [fromAddress, setFromAddress] = useState("");
  const [fromName, setFromName] = useState("");
  const [replyToAddress, setReplyToAddress] = useState("");
  const [replyToName, setReplyToName] = useState("");
  const [showDnsSettingsNotFound, setShowDnsSettingsNotFound] = useState(false);

  const { t } = useTranslation();
  const user = useUser();
  const { post, patch } = useApi();
  const navigate = useNavigate();

  useEffect(() => {
    if (user.sendgrid_verification_status === "completed") {
      navigate("/mail/overview");
    }
    setMailToSendInsructionsTo(user.email);
  }, [user, setMailToSendInsructionsTo, navigate]);

  async function startDomainAuthentication() {
    try {
      setIsLoading(true);
      await post(`/users/${user.id}/start-domain-verification`, {
        domain,
        email_to_send_to: mailToSendInsructionsTo,
      });
      await patchUser({ sendgrid_verification_status: "test_connection" });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function checkDomainAuthentication() {
    try {
      setIsLoading(true);
      const result = await post(
        `/users/${user.id}/complete-domain-verification`,
        {
          domain: user.sendgrid_domain,
        }
      );
      if (result) {
        navigate("/mail/overview");
      } else {
        setShowDnsSettingsNotFound(true);
        setTimeout(() => {
          setShowDnsSettingsNotFound(false);
        }, 1000);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function patchUser(body: any) {
    try {
      setIsLoading(true);
      await patch(`users/${user.id}`, body);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  if (!user.sendgrid_verification_status) {
    return (
      <Wrapper>
        <StartContainer>
          <Image src={image} />
          <TextContainer>
            <H4>{t("Mail onboarding")}</H4>
            <S1>
              {t(
                "Your mail has not been configured yet. Use the button below to start the onboarding now."
              )}
            </S1>
          </TextContainer>

          <Button
            onClick={() =>
              patchUser({ sendgrid_verification_status: "domain" })
            }
            disabled={false}
            text={t("Start onboarding now")}
          />
        </StartContainer>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <StepperContainer>
        <StepperItem
          status={
            user.sendgrid_verification_status === "domain"
              ? "active"
              : "inactive"
          }
          stepNumber={1}
          stepText={t("Domain")}
        />
        <StepperItem
          status={
            user.sendgrid_verification_status === "mail_details"
              ? "active"
              : "inactive"
          }
          stepNumber={2}
          stepText={t("Mail details")}
        />
        <StepperItem
          status={
            user.sendgrid_verification_status === "instructions"
              ? "active"
              : "inactive"
          }
          stepNumber={3}
          stepText={t("SPF records")}
        />
        <StepperItem
          status={
            user.sendgrid_verification_status === "test_connection"
              ? "active"
              : "inactive"
          }
          stepNumber={4}
          stepText={t("Test connection")}
        />
      </StepperContainer>

      {user.sendgrid_verification_status === "domain" && (
        <OnboardingCard>
          <P1>
            {t(
              "Add the domain of the email address you want to use as a sender. The domain is the part after the @ symbol. In case of info@leadsbeasts.com, it would be leadsbeasts.com."
            )}
          </P1>
          <Input
            id="domain-input-id"
            disabled={isLoading}
            value={domain}
            onChange={setDomain}
            placeholder={t("Enter your domain")}
            label={t("domain")}
          />

          <ButtonGroup>
            <Button
              disabled={isLoading || !domain}
              onClick={() =>
                patchUser({
                  sendgrid_domain: domain,
                  sendgrid_verification_status: "mail_details",
                })
              }
              text={t("Next")}
            />
          </ButtonGroup>
        </OnboardingCard>
      )}

      {user.sendgrid_verification_status === "mail_details" && (
        <OnboardingCard>
          <P1>{t("Add some details for the mails we're going to send.")}</P1>
          <Input
            value={fromAddress}
            onChange={setFromAddress}
            placeholder={t("Enter a from address")}
            disabled={isLoading}
            id={"from-address-input"}
            label={t("from address")}
          />
          <Input
            value={fromName}
            onChange={setFromName}
            placeholder={t("Enter a from name")}
            disabled={isLoading}
            id={"from-name-input"}
            label={t("from name")}
          />
          <Input
            value={replyToAddress}
            onChange={setReplyToAddress}
            placeholder={t("Enter a reply address")}
            disabled={isLoading}
            id={"reply-address-input"}
            label={t("reply address")}
          />
          <Input
            value={replyToName}
            onChange={setReplyToName}
            placeholder={t("Enter a reply name")}
            disabled={isLoading}
            id={"reply-name-input"}
            label={t("reply name")}
          />
          <ButtonGroup>
            <Button
              disabled={isLoading}
              onClick={() =>
                patchUser({
                  from_address: fromAddress,
                  from_name: fromName,
                  reply_to_address: replyToAddress,
                  reply_to_name: replyToName,
                  sendgrid_verification_status: "instructions",
                })
              }
              text={t("next")}
            />
          </ButtonGroup>
        </OnboardingCard>
      )}

      {user.sendgrid_verification_status === "instructions" && (
        <OnboardingCard>
          <P1>
            {t(
              "You will need to add some DNS records to your website. Do you want to do it yourself or do you want someone else to do it? We'll send instructions to the email provided below."
            )}
          </P1>
          <Input
            id="email-input-id"
            disabled={isLoading}
            value={mailToSendInsructionsTo}
            onChange={setMailToSendInsructionsTo}
            placeholder={t("Enter email")}
            label={t("email")}
          />
          <ButtonGroup>
            <Button
              disabled={isLoading}
              onClick={startDomainAuthentication}
              text={t("Next")}
            />
          </ButtonGroup>
        </OnboardingCard>
      )}

      {user.sendgrid_verification_status === "test_connection" && (
        <OnboardingCard>
          <P1>
            {t(
              "We will now check whether the DNS settings have been set correctly. We will do this every minute, or you can use the button below."
            )}
          </P1>
          <Input
            id="domain-input-id"
            disabled={true}
            value={user.sendgrid_domain}
            onChange={() => null}
            placeholder={t("Enter your domain")}
            label={t("domain")}
          />
          <ButtonGroup>
            <Button
              disabled={isLoading}
              onClick={checkDomainAuthentication}
              text={t("Check")}
            />
          </ButtonGroup>
        </OnboardingCard>
      )}
    </Wrapper>
  );
};

export default MailOnboarding;
