import { FC } from "react";
import { ModalFooter, ModalBody } from "./confirmation-modal.style";
import { ConfirmationModalProps } from "./confirmation-modal.types";
import P1 from "../../atoms/p1";
import { useTranslation } from "react-i18next";
import Modal from "../modal";
import Button from "../button";

const ConfirmationModal: FC<ConfirmationModalProps> = ({
  modalIsOpen,
  onConfirm,
  closeModal,
  bodyText,
  headerText,
}) => {
  const { t } = useTranslation();

  const handleConfirm = () => {
    onConfirm();
    closeModal();
  };

  return (
    <Modal
      modalIsOpen={modalIsOpen}
      headerText={t(headerText ?? "Confirm your choice")}
    >
      <ModalBody>
        <P1 width="">
          {bodyText ??
            t(
              "Are you sure you want to continue? Confirm your choice using the buttons below."
            )}
        </P1>
      </ModalBody>
      <ModalFooter>
        <Button
          mode="danger"
          variant="outline"
          text={t("Cancel")}
          onClick={closeModal}
        />
        <Button text={t("Confirm")} onClick={handleConfirm} />
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmationModal;
