import { FC, useMemo, useState } from "react";
import { Wrapper, Image, ImageContainer } from "./page-state.style";
import { PageStateProps } from "./page-state.types";
import { useTranslation } from "react-i18next";
import { BarLoader } from "react-spinners";
import theme from "../../../styles/theme";
import H4 from "../../atoms/h4";
import loading1 from "../../../media/images/loading-1.svg";
import loading2 from "../../../media/images/loading-2.svg";
import loading3 from "../../../media/images/loading-3.svg";
import loading4 from "../../../media/images/loading-4.svg";
import loading5 from "../../../media/images/loading-5.svg";
import loading6 from "../../../media/images/loading-6.svg";
import error1 from "../../../media/images/error-1.svg";
import error2 from "../../../media/images/error-2.svg";
import error3 from "../../../media/images/error-3.svg";
import error4 from "../../../media/images/error-4.svg";
import error5 from "../../../media/images/error-5.svg";
import payment from "../../../media/images/payment.svg";
import useRunOnce from "../../../hooks/use-run-once";

const PageState: FC<PageStateProps> = ({ state, errorText }) => {
  const { t } = useTranslation();

  const [image, setImage] = useState<string>();
  const [text, setText] = useState<string>();

  const loadingImages = useMemo(
    () => [loading1, loading2, loading3, loading4, loading5, loading6],
    []
  );
  const errorImages = useMemo(
    () => [error1, error2, error3, error4, error5],
    []
  );

  const loadingTexts = useMemo(
    () => [
      t("Loading up your leads..."),
      t("Generating growth strategies..."),
      t("Finding opportunities for your business..."),
      t("Polishing your pitch..."),
      t("Harnessing the power of data..."),
      t("Unleashing your business potential..."),
      t("Preparing a roadmap for success..."),
      t("Fueling your business growth..."),
      t("Searching for the perfect leads..."),
      t("Building connections for your venture..."),
      t("Crafting your winning formula..."),
      t("Sparking innovation and growth..."),
      t("Exploring new market possibilities..."),
      t("Building bridges to success..."),
      t("Optimizing your lead generation..."),
      t("Igniting your business prospects..."),
      t("Mapping out your path to success..."),
      t("Navigating the world of opportunities..."),
      t("Unlocking the potential of your business..."),
      t("Empowering your business journey..."),
      t("Building a solid foundation for growth..."),
      t("Preparing your business for takeoff..."),
      t("Elevating your lead generation game..."),
      t("Creating a buzz for your venture..."),
      t("Fueling your business with fresh leads..."),
    ],
    [t]
  );

  useRunOnce({
    fn: () => {
      if (state === "payment") {
        setText(t("Redirecting you to a secure payment portal"));
        setImage(payment);
      }
      if (state === "loading") {
        setImage(getRandomElement(loadingImages));
        setText(getRandomElement(loadingTexts));
      }
      if (state === "error") {
        setImage(getRandomElement(errorImages));
        setText(errorText);
      }
    },
  });

  function getRandomElement<T>(array: T[]): T {
    const randomIndex = Math.floor(Math.random() * array.length);
    return array[randomIndex];
  }

  return (
    <Wrapper>
      <ImageContainer>
        <Image src={image} alt={state} />
      </ImageContainer>

      <H4>{text}</H4>
      {state !== "error" && (
        <BarLoader
          loading={true}
          color={theme.colors.primary.filled[500]}
          width={400}
        />
      )}
    </Wrapper>
  );
};

export default PageState;
