import styled from "styled-components";
import TextProps from "../../types/text-props";

export const S1 = styled.p<TextProps>`
  font-family: ${({ theme }) => theme.typography.family};
  font-weight: ${({ theme }) => theme.typography.s1.weight};
  font-size: ${({ theme }) => theme.typography.s1.size};
  line-height: ${({ theme }) => theme.typography.s1.lineHeight};
  color: ${({ theme, color }) => color ?? theme.typography.colors.default};
  margin: 0;
  width: ${({ width }) => width ?? "max-content"};
`;

export default S1;
