import { FC, useEffect, useState } from "react";
import { CardContainer } from "./mail-overview.style";
import { MailOverviewProps, MailsResponse } from "./mail-overview.types";
import StatCard from "../../molecules/stat-card/stat-card";
import { useTranslation } from "react-i18next";
import Input from "../../molecules/input";
import Select from "../../molecules/select/select";
import Button from "../../molecules/button/button";
import Card from "../../molecules/card/card";
import { Container } from "../../../styles/commons.styles";
import useSWR from "swr";
import SendMailModal from "../../molecules/send-mail-modal";
import { useUser } from "../../../contexts/user-context";
import { useApi } from "../../../contexts/api-context";
import useDebounce from "../../../hooks/use-debounce";

const MailOverview: FC<MailOverviewProps> = () => {
  const [mailName, setMailName] = useState<string>("");
  const [mailList, setMailList] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [from, setFrom] = useState("");
  const [until, setUntil] = useState("");
  const [isMailDetailsModalOpen, setIsMailDetailsModalOpen] = useState(false);
  const [selectedMail, setSelectedMail] = useState("");
  const [isSendMailModalOpen, setIsSendMailModalOpen] = useState(false);

  const { t } = useTranslation();
  const user = useUser();
  const { get } = useApi();

  const { data: mails, mutate } = useSWR(user.id && "getMails", getMails, {
    refreshInterval: 60000,
    fallbackData: {
      mails: [],
      mails_sent: 0,
      distinct_lists: [],
    },
  });

  useEffect(() => {
    mutate();
  }, [mailList, from, until, mutate]);

  async function getMails() {
    try {
      const res = await get<MailsResponse>(`/users/${user.id}/mails`, {
        params: {
          mail_name: mailName,
          list_name: mailList,
          from: from && new Date(from).getTime(),
          until: until && new Date(until).getTime(),
        },
      });

      return res;
    } catch (error) {
      console.error(error);
    }
  }

  const handleMailNameChange = useDebounce(setMailName, setIsLoading, mutate);

  function selectMail(mailId: string) {
    setSelectedMail(mailId);
    setIsMailDetailsModalOpen(true);
  }

  return (
    <>
      <Container>
        <StatCard
          stat={mails && mails.mails_sent ? mails!.mails_sent : 0}
          description={t("Mails sent")}
          icon="paper-plane-outline"
        />
      </Container>
      <Container>
        <Input
          label={t("Mail name")}
          value={mailName}
          onChange={handleMailNameChange}
          placeholder={t("Enter mail name")}
          disabled={isLoading}
          id={"mail-name-input"}
        />
        <Select
          label={t("mail list")}
          placeholder={t("Select mail list")}
          value={mailList}
          onChange={(e) => setMailList(e.target.value)}
          disabled={isLoading}
          options={
            mails && mails.distinct_lists
              ? mails.distinct_lists.map((list_name) => ({
                  value: list_name,
                  label: list_name,
                }))
              : []
          }
        />
        <Input
          label={t("from")}
          value={from}
          onChange={setFrom}
          placeholder={t("date")}
          disabled={isLoading}
          id={"from-date-input"}
          type="date"
        />
        <Input
          label={t("until")}
          value={until}
          onChange={setUntil}
          placeholder={t("date")}
          disabled={isLoading}
          id={"until-date-input"}
          type="date"
        />
        <Button
          onClick={() => setIsSendMailModalOpen(true)}
          disabled={isLoading}
          icon="email-outline"
          text={t("new email")}
        />
      </Container>
      <CardContainer>
        {mails &&
          mails.mails &&
          mails.mails.map((mail) => (
            <Card
              status="completed"
              key={mail.id}
              title={mail.name}
              description={t("Sent on {{- date}} to {{mailList}}", {
                date: new Date(mail.sent_date).toLocaleDateString(),
                mailList: mail.list_name,
              })}
              PrimaryButton={
                <Button
                  mode="info"
                  size="small"
                  onClick={() => selectMail(mail.id)}
                  disabled={false}
                  text={t("view")}
                />
              }
            />
          ))}
      </CardContainer>

      <SendMailModal
        user={user}
        closeModal={() => setIsSendMailModalOpen(false)}
        modalIsOpen={isSendMailModalOpen}
      />
    </>
  );
};

export default MailOverview;
