import { ColumnDef } from "@tanstack/react-table";
import { LeadsTableRow } from "./leads.types";
import IndeterminateCheckbox from "../../molecules/indeterminate-checkbox";

export default [
  {
    id: "select",
    header: ({ table }) => (
      <IndeterminateCheckbox
        {...{
          checked: table.getIsAllRowsSelected(),
          indeterminate: table.getIsSomeRowsSelected(),
          onChange: table.getToggleAllRowsSelectedHandler(),
        }}
      />
    ),
    cell: ({ row }) => (
      <div className="px-1">
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      </div>
    ),
  },
  {
    header: "Company Name",
    accessorKey: "company_name",
  },
  {
    header: "Mail",
    accessorKey: "email",
  },
  {
    header: "Phone",
    accessorKey: "phone",
  },
  {
    header: "Industry",
    accessorKey: "industry",
  },
  {
    header: "Location",
    accessorKey: "location",
  },
] as ColumnDef<LeadsTableRow>[];
