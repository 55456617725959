import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 30px;
  gap: 30px;
  min-height: calc(100vh - 99px);
`;
