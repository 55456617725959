import { useState } from "react";

function useDebounce(
  setValue: (value: string) => void,
  setIsLoading: (value: boolean) => void,
  mutate: () => Promise<void>
) {
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  function debounceFunction(value: string) {
    setValue(value);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    setTimeoutId(
      setTimeout(async () => {
        setIsLoading(true);
        await mutate();
        setIsLoading(false);
      }, 750)
    );
  }

  return debounceFunction;
}

export default useDebounce;
