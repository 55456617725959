import { FC } from "react";
import {
  StepperItemContainer,
  StepperItemCircle,
  StepperText,
} from "./stepper-item.style";
import { StepperItemProps } from "./stepper-item.types";

const StepperItem: FC<StepperItemProps> = ({
  status,
  stepText,
  stepNumber,
}) => {
  return (
    <StepperItemContainer>
      <StepperItemCircle status={status}>{stepNumber}</StepperItemCircle>
      <StepperText status={status}>{stepText}</StepperText>
    </StepperItemContainer>
  );
};

export default StepperItem;
