import styled from "styled-components";

// Components
import Label from "../../atoms/label";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.basic.filled[100]};
`;

export const TableWrapper = styled.table`
  border-collapse: separate;
  border-spacing: 1px;
  background-color: ${({ theme }) => theme.colors.basic.filled[300]};
  table-layout: auto;
  width: 100%;
`;

export const TableHeaderRow = styled.tr`
  background-color: ${({ theme }) => theme.colors.basic.filled[100]};
`;

export const Th = styled.th`
  text-align: left;
  padding: 16px;
  height: 56px;

  :first-child {
    width: 16px;
  }
`;

export const Td = styled.td`
  :first-child {
    width: 16px;
  }

  :has(button) {
    width: 1px;
  }

  text-align: left;
  padding: 16px;
  max-width: 200px;
  white-space: nowrap;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: ${({ theme }) => theme.typography.colors.default};
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface TableRowProps {
  onClick?: () => void;
  disabled?: boolean;
}

export const Tr = styled.tr<TableRowProps>`
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  :nth-child(even) {
    background-color: #f7f9fc;

    :hover {
      filter: brightness(98%);
    }
  }

  :nth-child(odd) {
    background-color: #ffffff;

    :hover {
      filter: brightness(98%);
    }
  }
`;

export const Tbody = styled.tbody``;

export const TableHeader = styled(Label)`
  color: ${({ theme }) => theme.typography.colors.default};
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: unset;
  padding: 20px;
`;

export const PageSelectionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

export const PageSizeContainer = styled.div``;
