interface NavItem {
  id: number;
  label: string;
  icon: string;
  path: string;
  end: boolean;
}

const useNavItems = (): NavItem[] => [
  {
    id: 999,
    label: "Leads",
    icon: "award-outline",
    path: "leads",
    end: false,
  },
  {
    id: 998,
    label: "Searches",
    icon: "search-outline",
    path: "searches/overview",
    end: false,
  },
  {
    id: 997,
    label: "Mail",
    icon: "email-outline",
    path: "mail/overview",
    end: false,
  },
  {
    id: 996,
    label: "Settings",
    icon: "settings-outline",
    path: "settings",
    end: false,
  },
];

export default useNavItems;
