import styled from "styled-components";

export const Wrapper = styled.div`
  width: 250px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.basic.filled[100]};
  min-height: 100vh;
`;

export const Logo = styled.img`
  width: 250px;
  padding: 30px;
`;
