import { FC } from "react";
import { Wrapper } from "./enhanced-radio-button.style";
import { EnhancedRadioButtonProps } from "./enhanced-radio-button.types";
import Icon from "../icon";
import H6 from "../../atoms/h6";
import C1 from "../../atoms/c1";
import theme from "../../../styles/theme";

const EnhancedRadioButton: FC<EnhancedRadioButtonProps> = ({
  icon,
  title,
  caption,
  id,
  value,
  name,
  selected,
  setFieldValue,
}) => {
  return (
    <Wrapper
      onClick={() => setFieldValue(name, value)}
      htmlFor={id}
      selected={selected}
    >
      {icon && (
        <Icon
          Shape={icon}
          color={
            selected
              ? theme.colors.primary.filled[500]
              : theme.colors.basic.filled[500]
          }
          size={32}
        />
      )}
      {title && (
        <H6 color={theme.colors[selected ? "primary" : "basic"].filled[500]}>
          {title}
        </H6>
      )}
      {caption && (
        <C1 color={theme.colors[selected ? "primary" : "basic"].filled[500]}>
          {caption}
        </C1>
      )}
    </Wrapper>
  );
};

export default EnhancedRadioButton;
