import { FC } from "react";
import { FormContainer } from "./form.style";
import { FormProps } from "./form.types";
import { Formik } from "formik";
import Field from "../field";
import Button from "../button";

const Form: FC<FormProps> = ({
  initialValues,
  validationSchema,
  onSubmit,
  fields,
  actionButtonText,
  cancelButtonAction,
  cancelButtonText,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        await onSubmit(values);
        setSubmitting(false);
      }}
    >
      {({ isSubmitting, setFieldValue }) => (
        <FormContainer>
          {fields.map((props) => (
            <Field
              key={props.name}
              disabled={isSubmitting || props.disabled}
              setFieldValue={setFieldValue}
              {...props}
            />
          ))}
          <Button
            text={actionButtonText}
            type="submit"
            disabled={isSubmitting}
            isLoading={isSubmitting}
          />
          {cancelButtonAction && (
            <Button
              variant="outline"
              disabled={isSubmitting}
              onClick={() => cancelButtonAction()}
              text={cancelButtonText ?? "cancel"}
            />
          )}
        </FormContainer>
      )}
    </Formik>
  );
};

export default Form;
