import { FC, useMemo, useState } from "react";
import { SignInProps } from "./sign-in.types";
import { Card, Column, FullScreen } from "../../../styles/commons.styles";
import H1 from "../../atoms/h1";
import { useTranslation } from "react-i18next";
import S1 from "../../atoms/s1";
import Form from "../../molecules/form";
import * as Yup from "yup";
import YupPassword from "yup-password";
import { FieldProps } from "../../molecules/field/field.types";
import logo from "../../../media/images/logo.png";
import { Link } from "../../atoms/link";
import C1 from "../../atoms/c1";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import toast from "react-hot-toast";

YupPassword(Yup);

const SignIn: FC<SignInProps> = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const validationSchema = useMemo(
    () =>
      Yup.object({
        email: Yup.string()
          .email("Invalid email address.")
          .required("This field is required."),
        password: Yup.string().required("This field is required."),
      }),
    []
  );

  const fields = useMemo(
    () =>
      [
        {
          label: "email",
          placeholder: "Enter your email",
          mode: "input",
          name: "email",
        },

        {
          label: "password",
          placeholder: "Enter your password",
          type: "password",
          mode: "input",
          name: "password",
        },
      ] as FieldProps[],
    []
  );

  async function signIn({ email, password }: { [key: string]: any }) {
    try {
      setIsLoading(true);
      await signInWithEmailAndPassword(getAuth(), email, password);
    } catch (error) {
      toast.error(
        t("We're having some trouble signing you in. Please try again later.")
      );
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <FullScreen>
      <Card width="500px">
        <Column>
          <Column gap="0">
            <img alt="logo" width={200} src={logo} />
            <H1>{t("Sign in")}</H1>
            <S1>{t("Sign in to your account to continue")}</S1>
          </Column>
          {/* <Row>
            <Button
              width="100%"
              icon={googleLogo}
              variant="outline"
              mode="basic"
              disabled={isLoading}
              onClick={async () => await signInWithGoogle()}
            />
            <Button
              width="100%"
              icon={facebookLogo}
              variant="outline"
              mode="basic"
              disabled={true}
              onClick={async () => await signInWithFacebook()}
            />
            <Button
              width="100%"
              icon={appleLogo}
              variant="outline"
              mode="basic"
              disabled={true}
            />
          </Row> */}
          {/* <Row>
            <Divider />
            <div>
              <S1>{t("Or sign in with email")}</S1>
            </div>
            <Divider />
          </Row> */}
          <Form
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={validationSchema}
            onSubmit={signIn}
            fields={fields}
            actionButtonText={t("Sign in")}
          />
          <Column gap="5px">
            <C1>
              {t("Forgot your password?")}
              <Link to="/reset-password">{t("Reset password")}</Link>
            </C1>
            <C1>
              {t("Don't have an account?")}
              <Link to="/sign-up">{t("Sign up")}</Link>
            </C1>
          </Column>
        </Column>
      </Card>
    </FullScreen>
  );
};

export default SignIn;
