import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    transition: ${({ theme }) => theme.transition};
    font-family: ${({ theme }) => theme.typography.family};
  }
  
  body {
    margin: 0;
    background-color: ${({ theme }) => theme.colors.basic.filled[200]};
  }

  h1, h2, h3, h4, h5, h6, p, label {
    a {
      margin-left: 5px;
    }
  }
`;

export default GlobalStyle;
