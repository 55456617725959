import { FC, useState } from "react";
import { SearchCardsContainer } from "./searches-overview.style";
import {
  SearchesOverviewProps,
  SearchesResponse,
} from "./searches-overview.types";
import StatCard from "../../molecules/stat-card/stat-card";
import { useTranslation } from "react-i18next";
import Button from "../../molecules/button";
import Card from "../../molecules/card";
import useSWR from "swr";
import {
  Container,
  PageStateContainer,
  PageWrapper,
  Card as CommonCard,
} from "../../../styles/commons.styles";
import { useNavigate } from "react-router-dom";
import FileSaver from "file-saver";
import Datalist from "../../molecules/datalist";
import Banner from "../../molecules/banner/banner";
import { useUser } from "../../../contexts/user-context";
import { useApi } from "../../../contexts/api-context";
import PageState from "../../organisms/page-state";
import Transition from "../../molecules/transition";
import H6 from "../../atoms/h6";
import S2 from "../../atoms/s2";
import toast from "react-hot-toast";

const SearchesOverview: FC<SearchesOverviewProps> = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [industry, setIndustry] = useState<string>("");
  const [location, setLocation] = useState<string>("");
  const [locationOptions, setLocationOptions] = useState<string[]>([]);
  const [industryOptions, setIndustryOptions] = useState<string[]>([]);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const [isLoadingLocations, setIsLoadingLocations] = useState(false);
  const [isLoadingIndustries, setIsLoadingIndustries] = useState(false);
  const [isLoadingSearches, setIsLoadingSearches] = useState(true);
  const [isPageError, setIsPageError] = useState(false);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useUser();
  const { get, post } = useApi();

  const { data: searches, mutate } = useSWR("getSearches", getSearches, {
    refreshInterval: 20000,
    fallbackData: {
      results: [],
      number_of_completed_results: 0,
      leads_left: 0,
    },
  });

  async function getSearches() {
    try {
      const res = await get<SearchesResponse>(`/users/${id}/searches`);
      return res;
    } catch (error) {
      setIsPageError(true);
    } finally {
      setIsLoadingSearches(false);
    }
  }

  async function postSearch() {
    try {
      setIsLoading(true);
      await post(`/users/${id}/searches`, {
        location,
        industry,
      });
      await mutate();
      setIndustry("");
      setLocation("");
      setLocationOptions([]);
      setIndustryOptions([]);
    } catch (error) {
      toast.error(
        t("We weren't able to create your search. Please try again later.")
      );
    } finally {
      setIsLoading(false);
    }
  }

  async function handleDownload(searchId: string) {
    const response = await get<ArrayBuffer>(`/users/${id}/searches/file`, {
      params: {
        search: searchId,
      },
    });

    FileSaver.saveAs(
      new Blob([response]),
      `${new Date().toLocaleDateString()}.csv`
    );
  }

  function handleLocationDatalistChange(value: string) {
    setLocation(value);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    setTimeoutId(
      setTimeout(async () => {
        setLocationOptions([]);
        if (value) {
          setIsLoadingLocations(true);
          await get<string[]>(`/locations?term=${value}`)
            .then((response) => {
              setIsLoadingLocations(false);
              setLocationOptions(response);
            })
            .catch((error) => console.log(error));
        } else {
          setLocationOptions([]);
        }
      }, 750)
    );
  }

  function handleLocationOptionClick(value: string) {
    setLocation(value);
    setLocationOptions([]);
  }

  function handleIndustryDatalistChange(value: string) {
    setIndustry(value);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    setTimeoutId(
      setTimeout(async () => {
        setIndustryOptions([]);
        if (value) {
          setIsLoadingIndustries(true);
          await get<string[]>(`/industries?term=${value}`)
            .then((response) => setIndustryOptions(response))
            .catch((error) => console.log(error));
          setIsLoadingIndustries(false);
        } else {
          setIndustryOptions([]);
        }
      }, 750)
    );
  }

  function handleIndustryOptionClick(value: string) {
    setIndustry(value);
    setIndustryOptions([]);
  }

  async function handleOnEnter(e: React.KeyboardEvent<HTMLDivElement>) {
    if (
      e.key === "Enter" &&
      !isLoading &&
      location.length > 0 &&
      industry.length > 0
    ) {
      await postSearch();
    }
  }

  return (
    <Transition transitionKey={isLoadingSearches ? "loader" : "page"}>
      {isLoadingSearches ? (
        <PageStateContainer>
          <PageState state="loading" />
        </PageStateContainer>
      ) : (
        <PageWrapper>
          <Container>
            <StatCard
              icon="search-outline"
              stat={searches!.number_of_completed_results}
              description={t("Searches finished")}
            />
            <StatCard
              icon="activity-outline"
              stat={searches!.leads_left}
              description={t("Leads left this week")}
            />
          </Container>
          {searches!.leads_left <= 0 ? (
            <Banner
              text={t(
                "You've reached your max for this week. Up your subscription to increase."
              )}
              buttonAction={() => navigate("/settings")}
              buttonText={t("Settings")}
            />
          ) : isPageError ? (
            <PageStateContainer>
              <PageState
                state="error"
                errorText={t(
                  "We weren't able to fetch your searches. Please try again later."
                )}
              />
            </PageStateContainer>
          ) : (
            <Container>
              <Datalist
                id="industry-input"
                placeholder={t("Enter an industry")}
                value={industry}
                onChange={handleIndustryDatalistChange}
                onOptionClick={handleIndustryOptionClick}
                label={t("industry")}
                disabled={isLoading}
                options={industryOptions}
                onKeyDown={handleOnEnter}
                isLoading={isLoadingIndustries}
                resetOptions={() =>
                  setTimeout(() => {
                    setIndustryOptions([]);
                  }, 300)
                }
              />
              <Datalist
                id="location-input"
                placeholder={t("Enter a location")}
                value={location}
                onChange={handleLocationDatalistChange}
                onOptionClick={handleLocationOptionClick}
                label={t("location")}
                disabled={isLoading}
                options={locationOptions}
                onKeyDown={handleOnEnter}
                isLoading={isLoadingLocations}
                resetOptions={() =>
                  setTimeout(() => {
                    setLocationOptions([]);
                  }, 300)
                }
              />
              <Button
                onClick={postSearch}
                disabled={isLoading || !location || !industry}
                text={t("search")}
                icon="search-outline"
                isLoading={isLoading}
              />
            </Container>
          )}

          <SearchCardsContainer>
            {searches?.results!.length! > 0 ? (
              searches?.results.map((search) => {
                let primaryButton;
                let secondaryButton;

                if (search.status === "in progress") {
                  primaryButton = (
                    <Button
                      mode="info"
                      size="small"
                      disabled={true}
                      text={t("in progress")}
                    />
                  );
                }

                if (search.status === "completed") {
                  primaryButton = (
                    <Button
                      variant="outline"
                      size="small"
                      onClick={() => handleDownload(search.id)}
                      disabled={isLoading}
                      icon="download-outline"
                    />
                  );
                  secondaryButton = (
                    <Button
                      mode="primary"
                      size="small"
                      onClick={() => navigate(search.id)}
                      disabled={isLoading}
                      text={t("view")}
                    />
                  );
                }
                if (search.status === "error") {
                  primaryButton = (
                    <Button
                      variant="outline"
                      mode="danger"
                      size="small"
                      disabled={isLoading}
                      text={t("delete")}
                    />
                  );
                  secondaryButton = (
                    <Button
                      mode="info"
                      size="small"
                      onClick={() => {}}
                      disabled={isLoading}
                      text={t("retry")}
                    />
                  );
                }
                return (
                  <Card
                    key={search.id}
                    status={search.status}
                    title={`${search.industry}, ${search.location}`}
                    description={t("Added on {{- date}}", {
                      date: new Date(search.creation_date).toLocaleDateString(),
                    })}
                    PrimaryButton={primaryButton}
                    SecondaryButton={secondaryButton}
                  />
                );
              })
            ) : (
              <CommonCard>
                <H6>{t("No searches yet")}</H6>
                <S2>
                  {t("You can start a search using the text fields above.")}
                </S2>
              </CommonCard>
            )}
          </SearchCardsContainer>
        </PageWrapper>
      )}
    </Transition>
  );
};

export default SearchesOverview;
