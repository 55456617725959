import { FC, useState } from "react";
import { MailListResponse, MailListsProps } from "./mail-lists.types";
import { Container } from "../../../styles/commons.styles";
import Input from "../../molecules/input/input";
import { useTranslation } from "react-i18next";
import Button from "../../molecules/button";
import Table from "../../molecules/table";
import useMailListColumns from "./columns";
import useSWR from "swr";
import ConfirmationModal from "../../molecules/confirmation-modal";
import AddMailListModal from "../../molecules/add-mail-list-modal/add-mail-list-modal";
import { useUser } from "../../../contexts/user-context";
import { useApi } from "../../../contexts/api-context";
import useDebounce from "../../../hooks/use-debounce";

const MailLists: FC<MailListsProps> = () => {
  const [listName, setListName] = useState<string>("");
  const [rowSelection, setRowSelection] = useState({});
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDeleteListsModalOpen, setIsDeleteListsModalOpen] = useState(false);
  const [isAddMailListModalOpen, setIsAddMailListModalOpen] = useState(false);
  const [from, setFrom] = useState("");
  const [until, setUntil] = useState("");

  const { t } = useTranslation();
  const user = useUser();
  const { get, deleteResource } = useApi();

  const columns = useMailListColumns();

  const { data: lists, mutate } = useSWR(user.id && "getMailLists", getLists, {
    refreshInterval: 60000,
    fallbackData: {
      lists: [],
    },
  });

  async function getLists() {
    try {
      const res = await get<MailListResponse>(`/users/${user.id}/mail-lists`, {
        params: {
          list_name: listName,
          from: from && new Date(from).getTime(),
          until: until && new Date(until).getTime(),
        },
      });

      return res;
    } catch (error) {
      console.error(error);
    }
  }

  const handleListNameChange = useDebounce(setListName, setIsLoading, mutate);

  async function deleteLists() {
    setIsLoading(true);
    await deleteResource(`/users/${user.id}/mail-lists`, {
      params: {
        mail_lists: Object.keys(rowSelection).join(","),
      },
    });
    await mutate();
    setIsLoading(false);
    setRowSelection({});
  }

  return (
    <>
      <Container>
        <Input
          label={t("List name")}
          value={listName}
          onChange={handleListNameChange}
          placeholder={t("Enter list name")}
          disabled={isLoading}
          id="list-name-input"
        />
        <Input
          label={t("from")}
          value={from}
          onChange={setFrom}
          placeholder={t("date")}
          disabled={isLoading}
          id={"from-date-input"}
          type="date"
        />
        <Input
          label={t("until")}
          value={until}
          onChange={setUntil}
          placeholder={t("date")}
          disabled={isLoading}
          id={"until-date-input"}
          type="date"
        />
        <Button
          onClick={() => setIsDeleteListsModalOpen(true)}
          disabled={isLoading || Object.keys(rowSelection).length <= 0}
          mode="danger"
          text={t("Delete")}
        />

        <Button
          onClick={() => setIsAddMailListModalOpen(true)}
          disabled={isLoading}
          text={t("Add list")}
        />
      </Container>
      <Container>
        {lists && lists.lists && (
          <Table
            columns={columns}
            data={lists.lists}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
          />
        )}
      </Container>
      <AddMailListModal
        modalIsOpen={isAddMailListModalOpen}
        closeModal={() => setIsAddMailListModalOpen(false)}
        mutate={mutate}
      />
      <ConfirmationModal
        modalIsOpen={isDeleteListsModalOpen}
        onConfirm={deleteLists}
        closeModal={() => setIsDeleteListsModalOpen(false)}
        headerText={t("Delete mail lists")}
        bodyText={t("Are you sure you want to delete these lists?")}
      />
    </>
  );
};

export default MailLists;
