import styled from "styled-components";
import TextProps from "../../types/text-props";

export const H6 = styled.h6<TextProps>`
  font-family: ${({ theme }) => theme.typography.family};
  font-weight: ${({ theme }) => theme.typography.h6.weight};
  font-size: ${({ theme }) => theme.typography.h6.size};
  line-height: ${({ theme }) => theme.typography.h6.lineHeight};
  color: ${({ theme, color }) => color ?? theme.typography.colors.default};
  margin: 0;
  width: ${({ width }) => width ?? "max-content"};
`;

export default H6;
