import styled from "styled-components";
import { Container } from "../../../styles/commons.styles";

export const Wrapper = styled.div``;

export const FileCardContainer = styled(Container)`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 30px;
`;
