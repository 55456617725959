import { FC, useState } from "react";
import { AddMailListModalProps } from "./add-mail-list-modal.types";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import H2 from "../../atoms/h2";
import {
  ModalBody,
  ModalFooter,
} from "../confirmation-modal/confirmation-modal.style";
import Input from "../input";
import Button from "../button";
import { useUser } from "../../../contexts/user-context";
import { useApi } from "../../../contexts/api-context";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.25)",
    borderRadius: "4px",
    backgroundColor: "#FFFFFF",
    border: "none",
    padding: "40px",
    width: "500px",
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

const AddMailListModal: FC<AddMailListModalProps> = ({
  modalIsOpen,
  closeModal,
  mutate,
}) => {
  const [listName, setListName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();
  const user = useUser();
  const { post } = useApi();

  const handleConfirm = () => {
    try {
      setIsLoading(true);
      post(`users/${user.id}/mail-lists`, {
        name: listName,
        emails: [],
        creation_date: Date.now(),
        user_id: user.id,
      });

      mutate();
    } catch (error) {
    } finally {
      setIsLoading(false);
      setListName("");
      closeModal();
    }
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      style={customStyles}
      contentLabel="Confirmation Modal"
    >
      <H2>{t("Add a mail list")}</H2>
      <ModalBody>
        <Input
          value={listName}
          onChange={setListName}
          placeholder={t("Enter a list name")}
          disabled={isLoading}
          id={"add-list-name-input"}
          label={t("List name")}
        />
      </ModalBody>
      <ModalFooter>
        <Button mode="danger" onClick={closeModal} text={t("Cancel")} />
        <Button onClick={handleConfirm} text={t("Add")} />
      </ModalFooter>
    </Modal>
  );
};

export default AddMailListModal;
