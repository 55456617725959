import { ColumnDef } from "@tanstack/react-table";
import IndeterminateCheckbox from "../../molecules/indeterminate-checkbox";
import { useTranslation } from "react-i18next";
import { SearchDetailsColumns } from "./search-details.types";

const useSearchDetailsColumnss = () => {
  const { t } = useTranslation();

  return [
    {
      id: "select",
      header: ({ table }) => (
        <IndeterminateCheckbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }}
        />
      ),
      cell: ({ row }) => (
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      ),
    },
    {
      header: t("Company name"),
      accessorKey: "company_name",
    },
    {
      header: t("Website"),
      accessorKey: "website_url",
    },
    {
      header: t("Email"),
      accessorKey: "email",
    },
    {
      header: t("Phone"),
      accessorKey: "phone",
    },
  ] as ColumnDef<SearchDetailsColumns>[];
};

export default useSearchDetailsColumnss;
