import { FC, useEffect, useState } from "react";
import { FileCardContainer } from "./mail-templates.style";
import {
  MailTemplateResponse,
  MailTemplatesProps,
} from "./mail-templates.types";
import { Container } from "../../../styles/commons.styles";
import Input from "../../molecules/input/input";
import { useTranslation } from "react-i18next";
import Button from "../../molecules/button";
import FileCard from "../../molecules/file-card/file-card";
import useSWR from "swr";
import ConfirmationModal from "../../molecules/confirmation-modal";
import AddHtmlTemplateModal from "../../molecules/add-html-template-modal/add-html-template-modal";
import { useUser } from "../../../contexts/user-context";
import { useApi } from "../../../contexts/api-context";
import useDebounce from "../../../hooks/use-debounce";

const MailTemplates: FC<MailTemplatesProps> = () => {
  const [templateName, setTemplateName] = useState<string>("");
  const [selectedTemplates, setSelectedTemplates] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDeleteTemplatesModalOpen, setIsDeleteTemplatesModalOpen] =
    useState(false);
  const [isAddTemplateModalOpen, setIsAddTemplateModalOpen] = useState(false);
  const [from, setFrom] = useState("");
  const [until, setUntil] = useState("");

  const user = useUser();
  const { get, deleteResource } = useApi();

  const { t } = useTranslation();

  const { data: templates, mutate } = useSWR(
    user.id && "getMailTemplates",
    getMailTemplates,
    {
      refreshInterval: 120000,
      fallbackData: {
        templates: [],
      },
    }
  );

  async function getMailTemplates() {
    try {
      const res = await get<MailTemplateResponse>(
        `/users/${user.id}/mail-templates`,
        {
          params: {
            template_name: templateName,
            from: from && new Date(from).getTime(),
            until: until && new Date(until).getTime(),
          },
        }
      );

      return res;
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    mutate();
    //eslint-disable-next-line
  }, [from, until]);

  const handleTemplateNameChange = useDebounce(
    setTemplateName,
    setIsLoading,
    mutate
  );

  async function deleteTemplates() {
    setIsLoading(true);
    await deleteResource(`/users/${user.id}/mail-templates`, {
      params: {
        mail_templates: selectedTemplates.join(","),
      },
    });
    await mutate();
    setIsLoading(false);
    setSelectedTemplates([]);
  }

  return (
    <>
      <Container>
        <Input
          id="template-name-id"
          label={t("template name")}
          value={templateName}
          onChange={handleTemplateNameChange}
          placeholder={t("Enter template name")}
          disabled={false}
        />
        <Input
          label={t("from")}
          value={from}
          onChange={setFrom}
          placeholder={t("date")}
          disabled={isLoading}
          id={"from-date-input"}
          type="date"
        />
        <Input
          label={t("until")}
          value={until}
          onChange={setUntil}
          placeholder={t("date")}
          disabled={isLoading}
          id={"until-date-input"}
          type="date"
        />
        <Button
          onClick={() => setIsDeleteTemplatesModalOpen(true)}
          disabled={isLoading || selectedTemplates.length <= 0}
          mode="danger"
          text={t("Delete")}
        />

        <Button
          onClick={() => setIsAddTemplateModalOpen(true)}
          disabled={isLoading}
          mode="primary"
          text={t("Add template")}
        />
      </Container>
      <FileCardContainer>
        {templates &&
          templates.templates &&
          templates.templates.map((template) => (
            <FileCard
              key={template.id}
              fileName={template.name}
              disabled={isLoading}
              creationDate={new Date(
                template.creation_date
              ).toLocaleDateString()}
              selected={selectedTemplates.includes(template.id)}
              onClick={() => {
                if (selectedTemplates.includes(template.id)) {
                  setSelectedTemplates(
                    selectedTemplates.filter((id) => id !== template.id)
                  );
                } else {
                  setSelectedTemplates([...selectedTemplates, template.id]);
                }
              }}
            />
          ))}
      </FileCardContainer>
      <ConfirmationModal
        modalIsOpen={isDeleteTemplatesModalOpen}
        onConfirm={deleteTemplates}
        closeModal={() => setIsDeleteTemplatesModalOpen(false)}
        headerText={t("Delete mail templates")}
        bodyText={t("Are you sure you want to delete these templates?")}
      />
      {user && user.id && (
        <AddHtmlTemplateModal
          mutate={mutate}
          modalIsOpen={isAddTemplateModalOpen}
          closeModal={() => setIsAddTemplateModalOpen(false)}
          userId={user.id}
        />
      )}
    </>
  );
};

export default MailTemplates;
