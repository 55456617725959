import { authenticatedRoutes, unauthenticatedRoutes } from "./router";
import { useAuth } from "./contexts/auth-context";
import { RouterProvider } from "react-router-dom";
import { UserContextProvider } from "./contexts/user-context";
import { ApiContextProvider } from "./contexts/api-context";
import PageState from "./components/organisms/page-state";

const App = () => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return (
      <ApiContextProvider>
        <RouterProvider router={unauthenticatedRoutes} />
      </ApiContextProvider>
    );
  }

  if (isAuthenticated) {
    return (
      <ApiContextProvider>
        <UserContextProvider>
          <RouterProvider router={authenticatedRoutes} />
        </UserContextProvider>
      </ApiContextProvider>
    );
  }

  return <PageState state="loading" />;
};

export default App;
