import { FC, useState } from "react";
import { Wrapper, Container, FooterLink, SignOutLink } from "./footer.style";
import { FooterProps } from "./footer.types";
import { useTranslation } from "react-i18next";
import C1 from "../../atoms/c1";
import { getAuth, signOut } from "firebase/auth";
import ConfirmationModal from "../../molecules/confirmation-modal";

const Footer: FC<FooterProps> = () => {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const { t } = useTranslation();

  async function handleSignOut() {
    await signOut(getAuth());
  }

  return (
    <Wrapper>
      <Container>
        <C1>Leads Beasts V.O.F.</C1>
        <C1>
          <FooterLink target="_blank" href="https://www.leadsbeasts.com/faq">
            FAQ
          </FooterLink>
        </C1>
        <C1>
          <FooterLink
            target="_blank"
            href="https://www.leadsbeasts.com/contact"
          >
            {t("Contact")}
          </FooterLink>
        </C1>
      </Container>
      <Container>
        <SignOutLink onClick={() => setIsConfirmationModalOpen(true)}>
          {t("Sign out")}
        </SignOutLink>
        <C1>{t("All rights reserved")}</C1>
      </Container>
      <ConfirmationModal
        modalIsOpen={isConfirmationModalOpen}
        onConfirm={handleSignOut}
        closeModal={() => setIsConfirmationModalOpen(false)}
        headerText={t("Sign out")}
        bodyText={t("Are you sure you want to sign out?")}
      />
    </Wrapper>
  );
};

export default Footer;
