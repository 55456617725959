import { DefaultTheme } from "styled-components";

const theme: DefaultTheme = {
  typography: {
    family: "'Roboto', sans-serif",
    colors: {
      default: "#222B45",
      alternative: "#FFFFFF",
      link: "#0AB414",
      hint: "#8F9BB3",
      disabled: "rgba(143, 155, 179, 0.48)",
    },
    h1: {
      size: "36px",
      lineHeight: "2.2",
      weight: "700",
    },
    h2: {
      size: "32px",
      lineHeight: "2",
      weight: "700",
    },
    h3: {
      size: "30px",
      lineHeight: "1.8",
      weight: "700",
    },
    h4: {
      size: "26px",
      lineHeight: "1.6",
      weight: "700",
    },
    h5: {
      size: "22px",
      lineHeight: "1.4",
      weight: "700",
    },
    h6: {
      size: "18px",
      lineHeight: "1.2",
      weight: "700",
    },
    s1: {
      size: "15px",
      lineHeight: "1.6",
      weight: "400",
    },
    s2: {
      size: "13px",
      lineHeight: "1.6",
      weight: "400",
    },
    p1: {
      size: "15px",
      lineHeight: "1.6",
      weight: "400",
    },
    p2: {
      size: "13px",
      lineHeight: "1.6",
      weight: "400",
    },
    c1: {
      size: "12px",
      lineHeight: "1.6",
      weight: "400",
    },
    c2: {
      size: "12px",
      lineHeight: "1.6",
      weight: "400",
    },
    label: {
      size: "12px",
      lineHeight: "1.2",
      weight: "400",
    },
    link: {
      size: "inherit",
      lineHeight: "inherit",
      weight: "inherit",
    },
  },
  colors: {
    primary: {
      filled: {
        "100": "#D9FBCB",
        "200": "#ADF799",
        "300": "#74E864",
        "400": "#41D23D",
        "500": "#0AB414",
        "600": "#079A1D",
        "700": "#058122",
        "800": "#036824",
        "900": "#015625",
      },
      transparent: {
        "0.08": "rgba(184, 255, 221, 0.08)",
        "0.16": "rgba(184, 255, 221, 0.16)",
        "0.24": "rgba(128, 255, 142, 0.24)",
      },
    },
    basic: {
      filled: {
        "100": "#FFFFFF",
        "200": "#F7F9FC",
        "300": "#EDF1F7",
        "400": "#E4E9F2",
        "500": "#C5CEE0",
        "600": "#8F9BB3",
        "700": "#2E3A59",
        "800": "#222B45",
        "900": "#192038",
        "1000": "#151A30",
        "1100": "#101426",
      },
      transparent: {
        "0.08": "rgba(143, 155, 179, 0.08)",
        "0.16": "rgba(143, 155, 179, 0.16)",
        "0.24": "rgba(143, 155, 179, 0.24)",
        "0.32": "rgba(143, 155, 179, 0.32)",
        "0.40": "rgba(143, 155, 179, 0.40)",
        "0.48": "rgba(143, 155, 179, 0.48)",
      },
    },
    danger: {
      filled: {
        "100": "#FDE0D3",
        "200": "#FBBBA8",
        "300": "#F48D7B",
        "400": "#EA6159",
        "500": "#DD272D",
        "600": "#BE1C30",
        "700": "#9F1330",
        "800": "#800C2E",
        "900": "#6A072D",
      },
      transparent: {
        "0.08": "rgba(255, 61, 113, 0.08)",
        "0.16": "rgba(255, 61, 113, 0.16)",
        "0.24": "rgba(255, 61, 113, 0.24)",
        "0.32": "rgba(255, 61, 113, 0.32)",
        "0.40": "rgba(255, 61, 113, 0.40)",
        "0.48": "rgba(255, 61, 113, 0.48)",
      },
    },
    info: {
      filled: {
        "100": "#D5E5FE",
        "200": "#ACC9FE",
        "300": "#83A9FD",
        "400": "#6391FB",
        "500": "#3167F9",
        "600": "#234ED6",
        "700": "#1839B3",
        "800": "#0F2790",
        "900": "#091A77",
      },
      transparent: {
        "0.08": "rgba(0, 149, 255, 0.08)",
        "0.16": "rgba(0, 149, 255, 0.16)",
        "0.24": "rgba(0, 149, 255, 0.24)",
        "0.32": "rgba(0, 149, 255, 0.32)",
        "0.40": "rgba(0, 149, 255, 0.40)",
        "0.48": "rgba(0, 149, 255, 0.48)",
      },
    },
    warning: {
      filled: {
        "100": "#FEFBCB",
        "200": "#FEF798",
        "300": "#FEF265",
        "400": "#FDEC3F",
        "500": "#FCE300",
        "600": "#D8C000",
        "700": "#B59F00",
        "800": "#927F00",
        "900": "#786700",
      },
      transparent: {
        "0.08": "rgba(254, 251, 203, 0.08)",
        "0.16": "rgba(254, 251, 203, 0.16)",
        "0.24": "rgba(254, 251, 203, 0.24)",
        "0.32": "rgba(254, 251, 203, 0.32)",
        "0.40": "rgba(254, 251, 203, 0.40)",
        "0.48": "rgba(254, 251, 203, 0.48)",
      },
    },
    success: {
      filled: {
        "100": "#CAFCD8",
        "200": "#96FABB",
        "300": "#61F2A5",
        "400": "#39E69C",
        "500": "#00D68F",
        "600": "#00B88D",
        "700": "#009A85",
        "800": "#007C77",
        "900": "#006066",
      },
      transparent: {
        "0.08": "rgba(202, 252, 216, 0.08)",
        "0.16": "rgba(202, 252, 216, 0.16)",
        "0.24": "rgba(202, 252, 216, 0.24)",
        "0.32": "rgba(202, 252, 216, 0.32)",
        "0.40": "rgba(202, 252, 216, 0.40)",
        "0.48": "rgba(202, 252, 216, 0.48)",
      },
    },
  },
  borderRadius: "4px",
  shadow: "",
  transition: "300ms",
};

export default theme;
