import { FC, useMemo, useState } from "react";
import { ResetPasswordProps } from "./reset-password.types";
import { Card, Column, FullScreen } from "../../../styles/commons.styles";
import logo from "../../../media/images/logo.png";
import H1 from "../../atoms/h1";
import S1 from "../../atoms/s1";
import { useTranslation } from "react-i18next";
import Form from "../../molecules/form";
import * as Yup from "yup";
import YupPassword from "yup-password";
import { FieldProps } from "../../molecules/field/field.types";
import { useNavigate } from "react-router-dom";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import toast from "react-hot-toast";
import P1 from "../../atoms/p1";
import Button from "../../molecules/button";
import { ButtonContainer } from "./reset-password.style";
YupPassword(Yup);

const ResetPassword: FC<ResetPasswordProps> = () => {
  const [emailSent, setEmailSent] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const validationSchema = useMemo(
    () =>
      Yup.object({
        email: Yup.string()
          .email("Invalid email address.")
          .required("This field is required."),
      }),
    []
  );

  const fields = useMemo(
    () =>
      [
        {
          label: "email",
          placeholder: "Enter your email",
          mode: "input",
          name: "email",
        },
      ] as FieldProps[],
    []
  );

  async function resetPassword({ email }: { [key: string]: any }) {
    try {
      await sendPasswordResetEmail(getAuth(), email);
      setEmailSent(true);
    } catch (error) {
      toast.error(t("Something went wrong. Please try again later."));
    }
  }

  return (
    <FullScreen>
      <Card width="500px">
        {emailSent ? (
          <>
            <H1>{t("Email sent!")}</H1>
            <P1>{t("We've sent you an email to reset your password.")}</P1>
            <ButtonContainer>
              <Button
                text={t("Back to sign in")}
                onClick={() => navigate("/")}
              />
            </ButtonContainer>
          </>
        ) : (
          <Column>
            <Column gap="0">
              <img alt="logo" width={200} src={logo} />
              <H1>{t("Reset password")}</H1>
              <S1>
                {t("Fill in your email address below to reset your password")}
              </S1>
            </Column>
            <Form
              initialValues={{
                email: "",
              }}
              validationSchema={validationSchema}
              onSubmit={resetPassword}
              fields={fields}
              actionButtonText={t("reset password")}
              cancelButtonAction={() => navigate("/")}
              cancelButtonText={t("back")}
            />
          </Column>
        )}
      </Card>
    </FullScreen>
  );
};

export default ResetPassword;
