import { FC, HTMLProps, useEffect, useRef } from "react";
import { Wrapper, Display } from "./indeterminate-checkbox.style";
import { IndeterminateCheckboxProps } from "./indeterminate-checkbox.types";
import { Icon } from "@iconify/react";
import theme from "../../../styles/theme";
import Label from "../../atoms/label";

const IndeterminateCheckbox: FC<IndeterminateCheckboxProps> = ({
  indeterminate,
  label,
  className = "",
  ...rest
}: {
  indeterminate?: boolean;
} & HTMLProps<HTMLInputElement>) => {
  const ref = useRef<HTMLInputElement>(null!);

  useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate, rest.checked]);

  return (
    <Wrapper>
      <input type="checkbox" ref={ref} {...rest} />
      <Display>
        {!indeterminate && rest.checked && (
          <Icon
            icon="eva:checkmark-outline"
            color={theme.colors.info.filled[500]}
          />
        )}
        {indeterminate && !rest.checked && (
          <Icon
            icon="eva:minus-outline"
            color={theme.colors.info.filled[500]}
          />
        )}
      </Display>
      {label && <Label>{label}</Label>}
    </Wrapper>
  );
};

export default IndeterminateCheckbox;
