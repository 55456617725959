import styled from "styled-components";

import { Container } from "../../../styles/commons.styles";

export const SearchCardsContainer = styled(Container)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 30px;
`;
