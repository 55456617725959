import { FC } from "react";
import { Wrapper } from "./banner.style";
import { BannerProps } from "./banner.types";
import Button from "../button/button";
import H6 from "../../atoms/h6";
import theme from "../../../styles/theme";

const Banner: FC<BannerProps> = ({ text, buttonAction, buttonText }) => {
  return (
    <Wrapper>
      <H6 color={theme.colors.primary.filled[600]}>{text}</H6>
      <Button onClick={buttonAction} disabled={false} text={buttonText} />
    </Wrapper>
  );
};

export default Banner;
