import { FC } from "react";
import { Wrapper } from "./vertical-nav-item.style";
import { VerticalNavItemProps } from "./vertical-nav-item.types";
import S1 from "../../atoms/s1";
import { Icon } from "@iconify/react";
import theme from "../../../styles/theme";

const VerticalNavItem: FC<VerticalNavItemProps> = ({ icon, path, label }) => {
  return (
    <Wrapper to={path}>
      <Icon icon={`eva:${icon}`} />
      <S1 color={theme.typography.colors.hint}>{label}</S1>
    </Wrapper>
  );
};

export default VerticalNavItem;
