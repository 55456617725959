import React from "react";
import { createRoot } from "react-dom/client";

// Styles
import { ThemeProvider } from "styled-components";
import theme from "./styles/theme";
import GlobalStyles from "./styles/global.styles";

// Components
import App from "./App";

// Utils
import reportWebVitals from "./reportWebVitals";

// Localization
import "./i18n";
import { AuthContextProvider } from "./contexts/auth-context";
import Toaster from "./components/atoms/toaster";

const rootElement = document.getElementById("root");
if (!rootElement) throw new Error("Failed to find the root element");
const root = createRoot(rootElement);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <AuthContextProvider>
        <App />
        <Toaster />
      </AuthContextProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
