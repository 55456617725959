const languages = [
  {
    value: "en",
    label: "English",
  },
  {
    value: "de",
    label: "Deutsch",
  },
  {
    value: "it",
    label: "Italian",
  },
  {
    value: "es",
    label: "Espanol",
  },
  {
    value: "fr",
    label: "Francais",
  },
  {
    value: "nl",
    label: "Nederlands",
  },
];

export default languages;
