import styled from "styled-components";
import { WrapperProps } from "./enhanced-radio-button.types";

export const Wrapper = styled.label<WrapperProps>`
  border-radius: ${({ theme }) => theme.borderRadius};
  border: 1px solid
    ${({ theme, selected }) =>
      selected
        ? theme.colors.primary.filled[500]
        : theme.colors.basic.filled[300]};
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  transition: none;

  * {
    transition: none;
  }

  :hover {
    border-color: ${({ theme }) => theme.colors.primary.filled[500]};

    h6,
    p {
      color: ${({ theme }) => theme.colors.primary.filled[500]};
    }

    path {
      color: ${({ theme }) => theme.colors.primary.filled[500]};
    }
  }
`;

export const RadioButton = styled.input`
  position: absolute;
  opacity: 0;
`;
