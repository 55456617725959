import styled from "styled-components";
import CardProps from "../types/card-props";
import RowProps from "../types/row-props";
import ColumnProps from "../types/column-props";

export const Container = styled.div`
  display: flex;
  gap: 30px;
  align-items: end;
`;

export const FilterContainer = styled.div`
  display: flex;
  gap: 30px;
  align-items: end;
`;

export const Card = styled.div<CardProps>`
  padding: 30px;
  background-color: ${({ theme }) => theme.colors.basic.filled[100]};
  border-radius: ${({ theme }) => theme.borderRadius};
  border: 1px solid ${({ theme }) => theme.colors.basic.filled[300]};
  width: ${({ width }) => width ?? "100%"};
  transition: none;
`;

export const Column = styled.div<ColumnProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${({ gap }) => gap ?? "30px"};
`;

export const Row = styled.div<RowProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: ${({ gap }) => gap ?? "30px"};
`;

export const FullScreen = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding-top: 100px;
  padding-bottom: 100px;
`;

export const PageStateContainer = styled.div`
  height: calc(100vh - 200px);
`;

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 30px;
`;

export const ButtonContainer = styled.div`
  margin-top: 30px;
`;
