import { FC, useEffect, useState } from "react";
import { SendMailModalProps } from "./send-mail-modal.types";
import H2 from "../../atoms/h2";
import { ModalFooter } from "../confirmation-modal/confirmation-modal.style";
import Input from "../input";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import Button from "../button";
import { ModalBody } from "./send-mail-modal.style";
import Select from "../select";
import { useApi } from "../../../contexts/api-context";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.25)",
    borderRadius: "4px",
    backgroundColor: "#FFFFFF",
    border: "none",
    padding: "40px",
    width: "500px",
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

interface Option {
  value: string;
  label: string;
}

const SendMailModal: FC<SendMailModalProps> = ({
  user,
  modalIsOpen,
  closeModal,
}) => {
  const [subject, setSubject] = useState("");
  const [mailTemplate, setMailTemplate] = useState("");
  const [mailList, setMailList] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [mailLists, setMailLists] = useState<Option[]>([]);
  const [mailTemplates, setMailTemplates] = useState<Option[]>([]);

  const { t } = useTranslation();
  const { post, get } = useApi();

  async function sendMail() {
    try {
      setIsLoading(true);
      await post(`user/${user.id}/mail`, {
        subject,
        mail_list: mailList,
        mail_template: mailTemplate,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  interface MailList {
    id: string;
    count_of_contacts: number;
    name: string;
    emails: string[];
  }

  interface MailListsResponse {
    lists: MailList[];
  }

  interface MailTemplate {
    id: string;
    name: string;
  }

  interface MailTemplatesResponse {
    templates: MailTemplate[];
  }

  useEffect(() => {
    (async function () {
      try {
        setIsLoading(true);
        const lists = await get<MailListsResponse>(
          `users/${user.id}/mail-lists`
        );
        setMailLists(
          lists.lists.map((list: MailList) => ({
            value: list.id,
            label: list.name,
          }))
        );
        const templates = await get<MailTemplatesResponse>(
          `users/${user.id}/mail-templates`
        );
        setMailTemplates(
          templates.templates.map((template: MailTemplate) => ({
            value: template.id,
            label: template.name,
          }))
        );
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    })();
    //eslint-disable-next-line
  }, []);

  return (
    <Modal
      isOpen={modalIsOpen}
      style={customStyles}
      contentLabel="Send Mail Modal"
    >
      <H2>{t("Send mail")}</H2>
      <ModalBody>
        <Input
          value={subject}
          onChange={setSubject}
          placeholder={t("Enter a subject")}
          disabled={isLoading}
          id={"subject-input"}
          label={t("subject")}
        />
        <Select
          label={t("list")}
          value={mailTemplate}
          onChange={(e) => setMailList(e.target.value)}
          disabled={isLoading}
          options={mailLists}
          placeholder={t("Select a list")}
        />
        <Select
          label={t("template")}
          value={mailList}
          onChange={(e) => setMailTemplate(e.target.value)}
          disabled={isLoading}
          options={mailTemplates}
          placeholder={t("Select a template")}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          mode="danger"
          onClick={() => closeModal()}
          disabled={isLoading}
          text={t("Cancel")}
        />

        <Button
          onClick={sendMail}
          disabled={isLoading || !subject || !mailList || !mailTemplate}
          text={t("Send")}
        />
      </ModalFooter>
    </Modal>
  );
};

export default SendMailModal;
