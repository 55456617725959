import { FC } from "react";
import { Wrapper } from "./empty-state.style";
import { EmptyStateProps } from "./empty-state.types";
import S1 from "../../atoms/s1";
import Button from "../button";
import { Column } from "../../../styles/commons.styles";
import H5 from "../../atoms/h5";

const EmptyState: FC<EmptyStateProps> = ({
  title,
  subTitle,
  onClick,
  buttonText,
}) => {
  return (
    <Wrapper>
      <Column gap="">
        <H5>{title}</H5>
        <S1>{subTitle}</S1>
      </Column>
      {buttonText && <Button text={buttonText} onClick={onClick} />}
    </Wrapper>
  );
};

export default EmptyState;
