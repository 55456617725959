import { ColumnDef } from "@tanstack/react-table";
import IndeterminateCheckbox from "../../molecules/indeterminate-checkbox";
import { useTranslation } from "react-i18next";
import { MailListDetailsTableRow } from "./mail-list-details.types";

const useMailListDetailsColumns = () => {
  const { t } = useTranslation();

  return [
    {
      id: "select",
      header: ({ table }) => (
        <IndeterminateCheckbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }}
        />
      ),
      cell: ({ row }) => (
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      ),
    },
    {
      header: t("Mail address"),
      accessorKey: "email",
    },
  ] as ColumnDef<MailListDetailsTableRow>[];
};

export default useMailListDetailsColumns;
