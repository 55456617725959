import styled from "styled-components";

export const Wrapper = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  max-width: fit-content;

  input {
    display: none;
  }
`;

export const Display = styled.div`
  width: 16px;
  height: 16px;
  background-color: ${({ theme }) => theme.colors.info.transparent[0.16]};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.info.filled[500]};
  display: flex;
  justify-content: center;
  align-items: center;
`;
