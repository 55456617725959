import { FC } from "react";
import { Field as FormikField, useField } from "formik";
import { Wrapper, ErrorMessage, RadioInputGroup } from "./field.style";
import { FieldProps } from "./field.types";
import Datalist from "../datalist";
import Select from "../select";
import FileInput from "../file-input";
import Label from "../../atoms/label";
import theme from "../../../styles/theme";
import EnhancedRadioButton from "../enhanced-radio-button";

const Field: FC<FieldProps> = (props) => {
  const [field, meta] = useField(props);

  return (
    <Wrapper
      error={meta.touched && meta.error !== undefined && meta.error.length > 0}
    >
      {props.label && (
        <Label
          color={theme.colors.basic.filled[500]}
          required={props.required}
          htmlFor={props.id || props.name}
        >
          {props.label}
        </Label>
      )}

      {props.mode === "radio-enhanced" && (
        <RadioInputGroup role="group">
          {props.options.map((option) => (
            <EnhancedRadioButton
              icon={option.icon}
              title={option.title}
              caption={option.caption}
              key={option.id}
              selected={field.value === option.value}
              value={option.value}
              id={option.id}
              name={props.name}
              setFieldValue={props.setFieldValue!}
            />
          ))}
        </RadioInputGroup>
      )}

      {props.mode === "datalist" && (
        <Datalist
          placeholder={props.placeholder}
          value={props.value}
          onChange={props.onChange}
          id={props.id!}
          disabled={props.disabled ?? false}
          options={props.options}
          onOptionClick={props.onOptionClick}
          onKeyDown={props.onKeyDown}
          isLoading={props.isLoading ?? false}
          resetOptions={props.resetOptions}
        />
      )}

      {props.mode === "file" && (
        <FileInput
          setFile={props.setFile}
          accept={props.accept}
          isLoading={props.isLoading ?? false}
        />
      )}

      {props.mode === "select" && (
        <Select
          value={props.value}
          onChange={props.onChange}
          disabled={props.disabled ?? false}
          options={props.options}
        />
      )}

      {props.mode === "input" && (
        <FormikField as={props.mode} {...props} {...field} />
      )}

      {meta.touched && meta.error && <ErrorMessage>{meta.error}</ErrorMessage>}
    </Wrapper>
  );
};

export default Field;
