import { FC } from "react";
import { Wrapper, Placeholder } from "./nav-header.style";
import { NavHeaderProps } from "./nav-header.types";
import { useTranslation } from "react-i18next";
import H6 from "../../atoms/h6";
import C1 from "../../atoms/c1";

const NavHeader: FC<NavHeaderProps> = ({ email }) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <H6>{t("Hi!")}</H6>
      {email ? <C1>{email}</C1> : <Placeholder />}
    </Wrapper>
  );
};

export default NavHeader;
