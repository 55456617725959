import {
  createContext,
  useContext,
  ReactNode,
  useEffect,
  useState,
  useMemo,
} from "react";
import { FirebaseApp, initializeApp } from "firebase/app";
import {
  Auth,
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  UserCredential,
  onAuthStateChanged,
  User,
} from "firebase/auth";
import axios from "axios";

interface AuthContextType {
  isAuthenticated: boolean;
  signUp: (email: string, password: string) => Promise<UserCredential>;
  signIn: (email: string, password: string) => Promise<UserCredential>;
  signOut: () => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
  getIdToken: () => Promise<string | null>;
  user: User;
}

const AuthContext = createContext<AuthContextType | null>(null);

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
}

interface AuthProviderProps {
  children: ReactNode;
}

export function AuthContextProvider({ children }: AuthProviderProps) {
  const [firebaseUser, setFirebaseUser] = useState<User>({} as User);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);

  const config = useMemo(
    () => ({
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID,
    }),
    []
  );

  const app = useMemo<FirebaseApp>(() => initializeApp(config), [config]);
  const auth = useMemo<Auth>(() => getAuth(app), [app]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setFirebaseUser(user);

        const isNewUser = checkIfIsNewUser(user);

        if (isNewUser) {
          await signUpNewUser(user);
        }
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }

      setTimeout(() => {
        setIsAuthenticating(false);
      }, 500);
    });

    return () => unsubscribe();
  }, [auth]);

  const signUpNewUser = async (user: User) => {
    const subscription = localStorage.getItem("subscription") ?? "freemium";

    localStorage.removeItem("subscription");

    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/users/${user.uid}`,
      {
        email: user.email,
        subscription,
      },
      {
        headers: {
          Authorization: `Bearer ${await user.getIdToken()}`,
        },
      }
    );

    if (subscription !== "freemium") {
      window.location.href = response.data.stripeSessionUrl;
    }
  };

  const checkIfIsNewUser = (user: User) => {
    const dateString = user.metadata.creationTime!;
    const targetDate = new Date(dateString);
    const currentDate = new Date();
    const timeDifferenceInSeconds: number =
      (currentDate.getTime() - targetDate.getTime()) / 1000;

    // Check if the time difference is within 10 seconds
    if (timeDifferenceInSeconds <= 10) {
      return true;
    }

    return false;
  };

  const signUp = async (email: string, password: string) =>
    await createUserWithEmailAndPassword(auth, email, password);

  const signIn = async (email: string, password: string) =>
    await signInWithEmailAndPassword(auth, email, password);

  const signOut = async () => await auth.signOut();

  const resetPassword = async (email: string) =>
    await sendPasswordResetEmail(auth, email);

  const getIdToken = async () => await firebaseUser.getIdToken();

  const value: AuthContextType = {
    isAuthenticated,
    signUp,
    signIn,
    signOut,
    resetPassword,
    getIdToken,
    user: firebaseUser,
  };

  return (
    <AuthContext.Provider value={value}>
      {!isAuthenticating && children}
    </AuthContext.Provider>
  );
}
