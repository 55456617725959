import { FC } from "react";
import { Wrapper, Header, Footer } from "./card.style";
import { CardProps } from "./card.types";
import H6 from "../../atoms/h6";
import S2 from "../../atoms/s2";

const Card: FC<CardProps> = ({
  title,
  description,
  status,
  PrimaryButton,
  SecondaryButton,
}) => {
  return (
    <Wrapper status={status}>
      <Header>
        <H6>{title}</H6>
        <S2>{description}</S2>
      </Header>
      <Footer>
        {PrimaryButton}
        {SecondaryButton}
      </Footer>
    </Wrapper>
  );
};

export default Card;
