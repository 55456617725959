import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
`;

export const NavItem = styled(NavLink)`
  padding: 12px 16px;
  background-color: ${({ theme }) => theme.colors.basic.transparent[0.16]};
  border: 1px solid ${({ theme }) => theme.colors.basic.filled[600]};
  text-decoration: none;

  label {
    cursor: pointer;
  }

  :first-child {
    border-radius: 4px 0 0 4px;
  }

  :last-child {
    border-radius: 0 4px 4px 0;
  }

  &.active {
    background-color: ${({ theme }) => theme.colors.primary.transparent[0.08]};
    color: ${({ theme }) => theme.colors.primary.filled[500]};
    border: 1px solid ${({ theme }) => theme.colors.primary.filled[500]};
  }

  :hover {
    background-color: ${({ theme }) => theme.colors.basic.transparent[0.08]};
  }
`;
