import { FC, useState } from "react";
import { SearchDetailsProps } from "./search-details.types";
import {
  Container,
  FilterContainer,
  PageStateContainer,
} from "../../../styles/commons.styles";
import StatCard from "../../molecules/stat-card/stat-card";
import { useTranslation } from "react-i18next";
import Input from "../../molecules/input";
import Button from "../../molecules/button";
import useSearchDetailsColumns from "./columns";
import Table from "../../molecules/table";
import { useParams } from "react-router-dom";
import FileSaver from "file-saver";
import useSWR from "swr";
import { useUser } from "../../../contexts/user-context";
import { useApi } from "../../../contexts/api-context";
import useDebounce from "../../../hooks/use-debounce";
import PageState from "../../organisms/page-state";
import Transition from "../../molecules/transition";
import { CheckboxContainer, Wrapper } from "./search-details.style";
import Checkbox from "../../molecules/checkbox";

interface SearchResult {
  id: string;
  company_name: string;
  website: string;
  email: string;
  phone: string;
}

interface SearchDetailsResponse {
  results: SearchResult[];
  number_of_results: number;
}

const SearchDetails: FC<SearchDetailsProps> = () => {
  const [companyName, setCompanyName] = useState<string>("");
  const [rowSelection, setRowSelection] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isPageError, setIsPageError] = useState(false);
  const [hasEmail, setHasEmail] = useState(false);
  const [hasPhone, setHasPhone] = useState(false);

  const { t } = useTranslation();
  let { searchId } = useParams();

  const user = useUser();
  const { get } = useApi();

  const columns = useSearchDetailsColumns();

  const {
    data: searchResults,
    mutate,
    isLoading: isLoadingLeads,
  } = useSWR("getLeads", getSearchDetails, {
    refreshInterval: 60000,
    fallbackData: {
      results: [],
      number_of_results: 0,
    },
  });

  async function getSearchDetails() {
    try {
      const res = await get<SearchDetailsResponse>(
        `/users/${user.id}/searches/${searchId}`,
        {
          params: {
            company_name: companyName,
            has_email: hasEmail,
            has_phone: hasPhone,
          },
        }
      );

      return res;
    } catch (error) {
      setIsPageError(true);
    }
  }

  async function handleDownload() {
    const response = await get<ArrayBuffer>(`/users/${user.id}/searches/file`, {
      params: {
        leads: Object.keys(rowSelection).join(","),
      },
    });

    FileSaver.saveAs(
      new Blob([response]),
      `${new Date().toLocaleDateString()}.csv`
    );

    setRowSelection({});
  }

  const handleCompanyNameChange = useDebounce(
    setCompanyName,
    setIsLoading,
    mutate
  );

  if (isPageError && !isLoadingLeads) {
    return (
      <Transition transitionKey="error">
        <PageStateContainer>
          <PageState state={"error"} />
        </PageStateContainer>
      </Transition>
    );
  }

  return (
    <Transition transitionKey={isLoadingLeads ? "loader" : "page"}>
      {isLoadingLeads ? (
        <PageStateContainer>
          <PageState state={"loading"} />
        </PageStateContainer>
      ) : (
        <Wrapper>
          <Container>
            <StatCard
              stat={searchResults!.number_of_results}
              description={t("Results found")}
              icon="award-outline"
            />
          </Container>
          <FilterContainer>
            <CheckboxContainer>
              <Checkbox
                onClick={async () => {
                  setHasEmail((prevValue) => !prevValue);
                  setTimeout(async () => await mutate(), 500);
                }}
                checked={hasEmail === true}
                label={t("has email")}
              />
              <Checkbox
                onClick={async () => {
                  setHasPhone((prevValue) => !prevValue);
                  await mutate();
                }}
                checked={hasPhone === true}
                label={t("has phone")}
              />
            </CheckboxContainer>
            <Input
              value={companyName}
              onChange={handleCompanyNameChange}
              placeholder={t("Search for a company")}
              disabled={false}
              id="companyName"
              label={t("Company name")}
            />
            <Button
              icon="download-fill"
              onClick={handleDownload}
              disabled={!Object.entries(rowSelection).length}
              mode="warning"
              text={t("download {{numberOfSelectedLeads}} leads", {
                numberOfSelectedLeads: Object.entries(rowSelection).length,
              })}
            />
          </FilterContainer>
          <Container>
            {searchResults && searchResults.results && (
              <Table
                columns={columns}
                data={searchResults!!.results}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
              />
            )}
          </Container>
        </Wrapper>
      )}
    </Transition>
  );
};

export default SearchDetails;
