import { FC, useState } from "react";
import {
  MailListDetailsProps,
  MailListResponse,
} from "./mail-list-details.types";
import { Container } from "../../../styles/commons.styles";
import Input from "../../molecules/input";
import { useTranslation } from "react-i18next";
import Button from "../../molecules/button";
import Table from "../../molecules/table/table";
import useMailListDetailsColumns from "./columns";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";
import ConfirmationModal from "../../molecules/confirmation-modal";
import { useUser } from "../../../contexts/user-context";
import { useApi } from "../../../contexts/api-context";
import useDebounce from "../../../hooks/use-debounce";

const MailListDetails: FC<MailListDetailsProps> = () => {
  const [mailAddress, setMailAddress] = useState<string>("");
  const [rowSelection, setRowSelection] = useState({});
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDeleteContactModalOpen, setIsDeleteContactModalOpen] =
    useState(false);

  const columns = useMailListDetailsColumns();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const user = useUser();
  const { listId } = useParams();
  const { get, deleteResource } = useApi();

  const { data: list, mutate } = useSWR(user.id && "getMailList", getList, {
    refreshInterval: 60000,
    fallbackData: {
      emails: [],
    },
  });

  async function getList() {
    try {
      const res = await get<MailListResponse>(
        `/users/${user.id}/mail-lists/${listId}`,
        {
          params: {
            mail_address: mailAddress,
          },
        }
      );

      return res;
    } catch (error) {
      console.error(error);
    }
  }

  const handleMailAddressChange = useDebounce(
    setMailAddress,
    setIsLoading,
    mutate
  );

  async function deleteContacts() {
    setIsLoading(true);
    await deleteResource(`/users/${user.id}/mail-lists/${listId}/contacts`, {
      params: {
        emails: Object.keys(rowSelection).join(","),
      },
    });
    await mutate();
    setIsLoading(false);
    setRowSelection({});
  }

  return (
    <>
      <Container>
        <Input
          value={mailAddress}
          onChange={handleMailAddressChange}
          label={t("mail address")}
          placeholder={t("Search mail address")}
          disabled={isLoading}
          id="mail-address"
        />
        <Button
          onClick={() => setIsDeleteContactModalOpen(true)}
          disabled={isLoading || Object.keys(rowSelection).length <= 0}
          mode="danger"
          text={t("Delete")}
        />

        <Button
          onClick={() => navigate("/leads")}
          disabled={isLoading}
          text={t("Add new")}
        />
      </Container>
      <Container>
        {list && list.emails && (
          <Table
            columns={columns}
            data={list.emails}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            rowSelectionKey="email"
          />
        )}
      </Container>
      <ConfirmationModal
        modalIsOpen={isDeleteContactModalOpen}
        onConfirm={deleteContacts}
        closeModal={() => setIsDeleteContactModalOpen(false)}
        headerText={t("Delete contact")}
        bodyText={t(
          "Are you sure you want to delete these contacts from this list?"
        )}
      />
    </>
  );
};

export default MailListDetails;
