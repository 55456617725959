import styled from "styled-components";

import chevronDown from "../../../media/icons/chevron-down.svg";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
`;

export const StyledSelect = styled.select`
  border: 1px solid #e4e9f2;
  border-radius: 4px;
  padding: 10px 16px;
  background-image: url(${chevronDown});
  background-position: calc(100% - 12px) 50%; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  height: 42px;
  color: #8f9bb3;
  min-width: 80px;

  option {
    color: #8f9bb3;
  }

  :disabled {
    background-color: ${({ theme }) => theme.colors.basic.filled[200]};
    border-color: ${({ theme }) => theme.colors.basic.filled[400]};
    color: ${({ theme }) => theme.colors.basic.filled[500]};
  }
`;
