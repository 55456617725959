import styled from "styled-components";

export const Wrapper = styled.div``;

export const ModalBody = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
`;

export const Error = styled.div`
  color: red;
`;
