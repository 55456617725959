import { FC, useState } from "react";
import { TableProps } from "./table.types";
import {
  Wrapper,
  TableWrapper,
  Th,
  Tr,
  Td,
  Tbody,
  TableHeader,
  PaginationContainer,
  PageSelectionContainer,
  PageSizeContainer,
  TableHeaderRow,
} from "./table.style";

import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
  SortingState,
  getSortedRowModel,
} from "@tanstack/react-table";
import { Icon } from "@iconify/react";

import { useTranslation } from "react-i18next";

import Button from "../button";

import Label from "../../atoms/label";
import Select from "../select/select";

import EmptyState from "../empty-state/empty-state";

import pageSizeOptions from "./page-size-options.json";
import theme from "../../../styles/theme";

const Table: FC<TableProps> = ({
  data,
  columns,
  onRowClick,
  setRowSelection,
  rowSelection,
  rowSelectionKey = "id",
  emptyTableButtonClick,
  emptyTableButtonText,
  emptyTableSubTitle,
  emptyTableTitle,
}) => {
  const [sorting, setSorting] = useState<SortingState>([]);

  const table = useReactTable({
    getRowId: getRowId,
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    state: {
      rowSelection,
      sorting,
    },
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
  });

  const { t } = useTranslation();

  function getRowId(originalRow: any) {
    return originalRow[rowSelectionKey];
  }

  return (
    <Wrapper>
      <TableWrapper>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableHeaderRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Th
                  key={header.id}
                  onClick={header.column.getToggleSortingHandler()}
                  style={{
                    width:
                      header.getSize() !== 150 ? header.getSize() : undefined,
                  }}
                >
                  <TableHeader
                    {...{
                      className: header.column.getCanSort()
                        ? "cursor-pointer select-none"
                        : "",
                    }}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                    {{
                      asc: (
                        <Icon
                          icon="eva:chevron-up-fill"
                          color={theme.colors.primary.filled[500]}
                          width="24"
                          height="24"
                        />
                      ),
                      desc: (
                        <Icon
                          icon="eva:chevron-down-fill"
                          color={theme.colors.primary.filled[500]}
                          width="24"
                          height="24"
                        />
                      ),
                    }[header.column.getIsSorted() as string] ?? null}
                  </TableHeader>
                </Th>
              ))}
            </TableHeaderRow>
          ))}
        </thead>
        {data.length > 0 && (
          <Tbody>
            {table.getRowModel().rows.map((row) => (
              <Tr
                key={row.id}
                disabled={row.original.status !== "completed"}
                onClick={() =>
                  row.original.status === "completed" &&
                  onRowClick &&
                  onRowClick(row.original)
                }
              >
                {row.getVisibleCells().map((cell) => (
                  <Td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        )}
      </TableWrapper>
      {data.length === 0 && (
        <EmptyState
          title={emptyTableTitle}
          subTitle={emptyTableSubTitle}
          buttonText={emptyTableButtonText}
          onClick={emptyTableButtonClick}
        />
      )}
      {data.length > 0 && (
        <PaginationContainer>
          <PageSizeContainer>
            <Select
              options={pageSizeOptions}
              value={table.getState().pagination.pageSize}
              onChange={(e) => table.setPageSize(Number(e.target.value))}
              disabled={false}
            />
          </PageSizeContainer>
          <PageSelectionContainer>
            <Button
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
              text={t("Previous")}
            />

            <Label>
              {`${t("Page")} ${table.getState().pagination.pageIndex + 1} ${t(
                "of"
              )} ${table.getPageCount()}`}
            </Label>
            <Button
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
              text={t("Next")}
            />
          </PageSelectionContainer>
        </PaginationContainer>
      )}
    </Wrapper>
  );
};

export default Table;
